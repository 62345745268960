/*
***********************************
CIRCULO ASSETS
***********************************
*/
.circle-assets {
	display: table;
	height: 102px;
	width: 102px;
	font-size: 45px;
	text-align: center;
	color: #FFF;
	font-family: sans-serif;
	font-weight: bold;
	overflow: hidden;
	border-radius: 50%;
	margin: 0 auto;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a3d165+0,7eb337+100 */
	background: #a3d165; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjYTNkMTY1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzdlYjMzNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background: -moz-linear-gradient(-45deg,  #a3d165 0%, #7eb337 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#a3d165), color-stop(100%,#7eb337)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(-45deg,  #a3d165 0%,#7eb337 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(-45deg,  #a3d165 0%,#7eb337 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(-45deg,  #a3d165 0%,#7eb337 100%); /* IE10+ */
	background: linear-gradient(135deg,  #a3d165 0%,#7eb337 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a3d165', endColorstr='#7eb337',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
}

.table tr th {
  background: #000;
  color: #8cc63e;
}

.shadow-verdonha {
	text-shadow:
	rgb(127, 180, 56) 1px 1px,
	rgb(127, 180, 56) 2px 2px,
	rgb(127, 180, 56) 3px 3px,
	rgb(127, 180, 56) 4px 4px,
	rgb(127, 180, 56) 5px 5px,
	rgb(127, 180, 56) 6px 6px,
	rgb(127, 180, 56) 7px 7px,
	rgb(127, 180, 56) 8px 8px,
	rgb(127, 180, 56) 9px 9px,
	rgb(127, 180, 56) 10px 10px,
	rgb(127, 180, 56) 11px 11px,
	rgb(127, 180, 56) 12px 12px,
	rgb(127, 180, 56) 13px 13px,
	rgb(127, 180, 56) 14px 14px,
	rgb(127, 180, 56) 15px 15px,
	rgb(127, 180, 56) 16px 16px,
	rgb(127, 180, 56) 17px 17px,
	rgb(127, 180, 56) 18px 18px,
	rgb(127, 180, 56) 19px 19px,
	rgb(127, 180, 56) 20px 20px,
	rgb(127, 180, 56) 21px 21px,
	rgb(127, 180, 56) 22px 22px,
	rgb(127, 180, 56) 23px 23px,
	rgb(127, 180, 56) 24px 24px,
	rgb(127, 180, 56) 25px 25px,
	rgb(127, 180, 56) 26px 26px,
	rgb(127, 180, 56) 27px 27px,
	rgb(127, 180, 56) 28px 28px,
	rgb(127, 180, 56) 29px 29px,
	rgb(127, 180, 56) 30px 30px,
	rgb(127, 180, 56) 31px 31px,
	rgb(127, 180, 56) 32px 32px,
	rgb(127, 180, 56) 33px 33px,
	rgb(127, 180, 56) 34px 34px,
	rgb(127, 180, 56) 35px 35px,
	rgb(127, 180, 56) 36px 36px,
	rgb(127, 180, 56) 37px 37px,
	rgb(127, 180, 56) 38px 38px,
	rgb(127, 180, 56) 39px 39px,
	rgb(127, 180, 56) 40px 40px,
	rgb(127, 180, 56) 41px 41px,
	rgb(127, 180, 56) 42px 42px,
	rgb(127, 180, 56) 43px 43px,
	rgb(127, 180, 56) 44px 44px,
	rgb(127, 180, 56) 45px 45px,
	rgb(127, 180, 56) 46px 46px,
	rgb(127, 180, 56) 47px 47px,
	rgb(127, 180, 56) 48px 48px,
	rgb(127, 180, 56) 49px 49px,
	rgb(127, 180, 56) 50px 50px,
	rgb(127, 180, 56) 51px 51px,
	rgb(127, 180, 56) 52px 52px,
	rgb(127, 180, 56) 53px 53px,
	rgb(127, 180, 56) 54px 54px,
	rgb(127, 180, 56) 55px 55px,
	rgb(127, 180, 56) 56px 56px,
	rgb(127, 180, 56) 57px 57px,
	rgb(127, 180, 56) 58px 58px,
	rgb(127, 180, 56) 59px 59px,
	rgb(127, 180, 56) 60px 60px,
	rgb(127, 180, 56) 61px 61px,
	rgb(127, 180, 56) 62px 62px,
	rgb(127, 180, 56) 63px 63px,
	rgb(127, 180, 56) 64px 64px,
	rgb(127, 180, 56) 65px 65px,
	rgb(127, 180, 56) 66px 66px,
	rgb(127, 180, 56) 67px 67px,
	rgb(127, 180, 56) 68px 68px,
	rgb(127, 180, 56) 69px 69px,
	rgb(127, 180, 56) 70px 70px,
	rgb(127, 180, 56) 71px 71px,
	rgb(127, 180, 56) 72px 72px,
	rgb(127, 180, 56) 73px 73px,
	rgb(127, 180, 56) 74px 74px,
	rgb(127, 180, 56) 75px 75px,
	rgb(127, 180, 56) 76px 76px,
	rgb(127, 180, 56) 77px 77px,
	rgb(127, 180, 56) 78px 78px,
	rgb(127, 180, 56) 79px 79px,
	rgb(127, 180, 56) 80px 80px,
	rgb(127, 180, 56) 81px 81px,
	rgb(127, 180, 56) 82px 82px,
	rgb(127, 180, 56) 83px 83px,
	rgb(127, 180, 56) 84px 84px,
	rgb(127, 180, 56) 85px 85px,
	rgb(127, 180, 56) 86px 86px,
	rgb(127, 180, 56) 87px 87px,
	rgb(127, 180, 56) 88px 88px,
	rgb(127, 180, 56) 89px 89px,
	rgb(127, 180, 56) 90px 90px,
	rgb(127, 180, 56) 91px 91px,
	rgb(127, 180, 56) 92px 92px,
	rgb(127, 180, 56) 93px 93px,
	rgb(127, 180, 56) 94px 94px,
	rgb(127, 180, 56) 95px 95px,
	rgb(127, 180, 56) 96px 96px,
	rgb(127, 180, 56) 97px 97px,
	rgb(127, 180, 56) 98px 98px,
	rgb(127, 180, 56) 99px 99px,
	rgb(127, 180, 56) 100px 100px,
	rgb(127, 180, 56) 101px 101px,
	rgb(127, 180, 56) 102px 102px,
	rgb(127, 180, 56) 103px 103px,
	rgb(127, 180, 56) 104px 104px,
	rgb(127, 180, 56) 105px 105px,
	rgb(127, 180, 56) 106px 106px,
	rgb(127, 180, 56) 107px 107px,
	rgb(127, 180, 56) 108px 108px,
	rgb(127, 180, 56) 109px 109px,
	rgb(127, 180, 56) 110px 110px,
	rgb(127, 180, 56) 111px 111px,
	rgb(127, 180, 56) 112px 112px,
	rgb(127, 180, 56) 113px 113px,
	rgb(127, 180, 56) 114px 114px,
	rgb(127, 180, 56) 115px 115px,
	rgb(127, 180, 56) 116px 116px,
	rgb(127, 180, 56) 117px 117px,
	rgb(127, 180, 56) 118px 118px,
	rgb(127, 180, 56) 119px 119px,
	rgb(127, 180, 56) 120px 120px,
	rgb(127, 180, 56) 121px 121px,
	rgb(127, 180, 56) 122px 122px,
	rgb(127, 180, 56) 123px 123px,
	rgb(127, 180, 56) 124px 124px,
	rgb(127, 180, 56) 125px 125px,
	rgb(127, 180, 56) 126px 126px,
	rgb(127, 180, 56) 127px 127px,
	rgb(127, 180, 56) 128px 128px,
	rgb(127, 180, 56) 129px 129px,
	rgb(127, 180, 56) 130px 130px,
	rgb(127, 180, 56) 131px 131px,
	rgb(127, 180, 56) 132px 132px,
	rgb(127, 180, 56) 133px 133px,
	rgb(127, 180, 56) 134px 134px,
	rgb(127, 180, 56) 135px 135px,
	rgb(127, 180, 56) 136px 136px,
	rgb(127, 180, 56) 137px 137px,
	rgb(127, 180, 56) 138px 138px,
	rgb(127, 180, 56) 139px 139px,
	rgb(127, 180, 56) 140px 140px,
	rgb(127, 180, 56) 141px 141px,
	rgb(127, 180, 56) 142px 142px,
	rgb(127, 180, 56) 143px 143px,
	rgb(127, 180, 56) 144px 144px,
	rgb(127, 180, 56) 145px 145px,
	rgb(127, 180, 56) 146px 146px,
	rgb(127, 180, 56) 147px 147px,
	rgb(127, 180, 56) 148px 148px,
	rgb(127, 180, 56) 149px 149px,
	rgb(127, 180, 56) 150px 150px,
	rgb(127, 180, 56) 151px 151px,
	rgb(127, 180, 56) 152px 152px,
	rgb(127, 180, 56) 153px 153px,
	rgb(127, 180, 56) 154px 154px,
	rgb(127, 180, 56) 155px 155px,
	rgb(127, 180, 56) 156px 156px,
	rgb(127, 180, 56) 157px 157px,
	rgb(127, 180, 56) 158px 158px,
	rgb(127, 180, 56) 159px 159px,
	rgb(127, 180, 56) 160px 160px,
	rgb(127, 180, 56) 161px 161px,
	rgb(127, 180, 56) 162px 162px,
	rgb(127, 180, 56) 163px 163px,
	rgb(127, 180, 56) 164px 164px,
	rgb(127, 180, 56) 165px 165px,
	rgb(127, 180, 56) 166px 166px,
	rgb(127, 180, 56) 167px 167px,
	rgb(127, 180, 56) 168px 168px,
	rgb(127, 180, 56) 169px 169px,
	rgb(127, 180, 56) 170px 170px,
	rgb(127, 180, 56) 171px 171px,
	rgb(127, 180, 56) 172px 172px,
	rgb(127, 180, 56) 173px 173px,
	rgb(127, 180, 56) 174px 174px,
	rgb(127, 180, 56) 175px 175px,
	rgb(127, 180, 56) 176px 176px,
	rgb(127, 180, 56) 177px 177px,
	rgb(127, 180, 56) 178px 178px,
	rgb(127, 180, 56) 179px 179px,
	rgb(127, 180, 56) 180px 180px,
	rgb(127, 180, 56) 181px 181px,
	rgb(127, 180, 56) 182px 182px,
	rgb(127, 180, 56) 183px 183px,
	rgb(127, 180, 56) 184px 184px,
	rgb(127, 180, 56) 185px 185px,
	rgb(127, 180, 56) 186px 186px,
	rgb(127, 180, 56) 187px 187px,
	rgb(127, 180, 56) 188px 188px,
	rgb(127, 180, 56) 189px 189px,
	rgb(127, 180, 56) 190px 190px,
	rgb(127, 180, 56) 191px 191px,
	rgb(127, 180, 56) 192px 192px,
	rgb(127, 180, 56) 193px 193px,
	rgb(127, 180, 56) 194px 194px,
	rgb(127, 180, 56) 195px 195px,
	rgb(127, 180, 56) 196px 196px,
	rgb(127, 180, 56) 197px 197px,
	rgb(127, 180, 56) 198px 198px,
	rgb(127, 180, 56) 199px 199px,
	rgb(127, 180, 56) 200px 200px;
}

@font-face {
    font-family: 'ModernPictogramsNormal';
    src: url('fonts/modernpics-webfont.eot');
    src: url('fonts/modernpics-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/modernpics-webfont.woff') format('woff'),
         url('fonts/modernpics-webfont.ttf') format('truetype'),
         url('fonts/modernpics-webfont.svg#ModernPictogramsNormal') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*
***********************************
ICONES ASSETS
***********************************
*/
@font-face {
	font-family: 'repucon-icon';
	src:url('../../dist/fonts/repucom-icon.eot?4gfuzy');
	src:url('../../dist/fonts/repucom-icon.eot?4gfuzy#iefix') format('embedded-opentype'),
		url('../../dist/fonts/repucom-icon.ttf?4gfuzy') format('truetype'),
		url('../../dist/fonts/repucom-icon.woff?4gfuzy') format('woff'),
		url('../../dist/fonts/repucom-icon.svg?4gfuzy#repucon-icon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'repucon-icon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-respeito:before {
	content: "\e62a";
}
.icon-seta-to-up:before {
	content: "\e62f";
}
.icon-seta-to-down:before {
	content: "\e630";
}
.icon-seta-to-left:before {
	content: "\e600";
}
.icon-seta-to-right:before {
	content: "\e601";
}
.icon-artigos:before {
	content: "\e602";
}
.icon-basquete-itens:before {
	content: "\e603";
}
.icon-bola-basquete:before {
	content: "\e604";
}
.icon-bola-futebol:before {
	content: "\e605";
}
.icon-bola-volei:before {
	content: "\e606";
}
.icon-busca:before {
	content: "\e607";
}
.icon-cadeado:before {
	content: "\e608";
}
.icon-celebridades:before {
	content: "\e609";
}
.icon-consultoria:before {
	content: "\e60a";
}
.icon-entre-em-contato:before {
	content: "\e60b";
}
.icon-entrega:before {
	content: "\e60c";
}
.icon-entretenimento:before {
	content: "\e60d";
}
.icon-esportes-itens:before {
	content: "\e60e";
}
.icon-esportes:before {
	content: "\e60f";
}
.icon-estrategia-de-campo:before {
	content: "\e610";
}
.icon-estrela-outracoisa:before {
	content: "\e611";
}
.icon-estudos:before {
	content: "\e612";
}
.icon-etica:before {
	content: "\e613";
}
.icon-excelencia:before {
	content: "\e614";
}
.icon-fale-conosco:before {
	content: "\e615";
}
.icon-faq:before {
	content: "\e616";
}
.icon-fecha-no-xis:before {
	content: "\e617";
}
.icon-foco:before {
	content: "\e618";
}
.icon-gestao-lideranca:before {
	content: "\e619";
}
.icon-go-to-top:before {
	content: "\e61a";
}
.icon-ilumiero:before {
	content: "\e61b";
}
.icon-indica-scroll:before {
	content: "\e61c";
}
.icon-infograficos:before {
	content: "\e61d";
}
.icon-jogador:before {
	content: "\e61e";
}
.icon-jogadores:before {
	content: "\e61f";
}
.icon-lupa-money:before {
	content: "\e620";
}
.icon-medalha:before {
	content: "\e621";
}
.icon-metodologia-global:before {
	content: "\e622";
}
.icon-noticias:before {
	content: "\e623";
}
.icon-ok-confirmado:before {
	content: "\e624";
}
.icon-onde-estamos:before {
	content: "\e625";
}
.icon-orcamento-pesquisa:before {
	content: "\e626";
}
.icon-orelha-ouvideira:before {
	content: "\e627";
}
.icon-plataforma-online:before {
	content: "\e628";
}
.icon-press-room:before {
	content: "\e629";
}
.icon-retornero:before {
	content: "\e62b";
}
.icon-seta-big-left:before {
	content: "\e62c";
}
.icon-seta-big-right:before {
	content: "\e62d";
}
.icon-seta-download:before {
	content: "\e62e";
}
.icon-sobre-ibope:before {
	content: "\e631";
}
.icon-social-facebook:before {
	content: "\e632";
}
.icon-social-linkedin:before {
	content: "\e633";
}
.icon-social-twitter:before {
	content: "\e634";
}
.icon-solicitar-orcamento:before {
	content: "\e635";
}
.icon-sustentabilidade:before {
	content: "\e636";
}
.icon-videos:before {
	content: "\e637";
}
.icon-visao-do-jogo:before {
	content: "\e638";
}
.icon-btn-x:before {
	content: "\e800";
}
.icon-celebridades-comp:before {
	content: "\e801";
}
.icon-tempo:before {
	content: "\e802";
}
.icon-caracion-esport:before {
	content: "\e803";
}
