@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic);

.single-rankings .attachment-full.size-full {
  width: 100%;
  height: auto;
}

/*
  Thin    100
  Thin    100 Italic
  Light   300
  Light   300 Italic
  Normal    400
  Normal    400 Italic
  Medium    500
  Medium    500 Italic
  Bold    700
  Bold    700 Italic
  Ultra-Bold  900
  Ultra-Bold  900 Italic

  h1 {
    font-family: 'Roboto', Arial, serif;
    font-weight: 400;
  }
*/

html {
  overflow-x: hidden;
}

.logradouros {
  font-size: 17px;
}

.modal-body {
  padding: 10px 80px 40px;
}
.modal-title {
  font-size: 45px;
  padding: 10px 20px 0px;
  color: #8cc63e;
  font-weight: 100;
  font-family: 'Roboto';
}
.modal-header {
  border: none;
}
/*===========================
=            SSA            =
===========================*/

.ssba {
  padding-top: 40px;
}

/*=====  End of SSA  ======*/


/*
***********************************
BODY
***********************************
*/

body {
  font-family: 'Roboto';
  -webkit-font-smoothing:antialiased;
  overflow-x:hidden;
  background: #8cc63e;

}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: none;
}

h1 {
  font-weight: 100;
  font-size: 55px;
  letter-spacing: -2px;
}

h2 {
  font-weight: 100;
  font-size: 46px;
  letter-spacing: -2px;
}

h2 strong, h2 b {
  font-weight: 900;
}

h3 {
  font-size:32px;
  font-weight:100;
  letter-spacing: -2px;
}

h4 {
  font-size:20px;
  font-weight:900;
}

p {
  font-weight: 300;
  font-size: 17px;
}

button, input, select,textarea,
button:focus, input:focus, select:focus, textarea:focus {
  outline:none !important;
  box-shadow:none !important;
  border-color: inherit !important;
  position:relative !important;
}

.divisor-assets {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #2d3232;
  margin-top: 100px;
  margin-bottom: 50px;
  color: #FFF;
}

.divisor-assets h3 {
  font-weight: 100;
  margin: 0;
}

.divisor-assets h3 span {
  position: relative;
  bottom: -2px;
}

.margin-divisor {
  margin-top:70px !important;
  margin-bottom:70px !important;
}

.centralizer-total {
  display:table;
  position:relative;
  width:100%;
  height:100%;
  background:transparent;
}

.centralizer-sub {
  display:table-cell;
  vertical-align:middle;
}
.banner {
  height: 100px;
  background: #2d3232;
}
.navbar-header {
  background: #2d3232;
  padding-bottom: 10px;
}
.navbar {
  margin-bottom: 0px !important;
}
#megatop>.container {
  padding: 0 !important;
}
.navbar-nav {
  margin-top: 23px;
}
.navbar-header {
  padding: 0 20px;
}
.navbar-nav>li>a {
  font-weight: 300;
  color: #a7a9ac;
  font-size: 17px;
}

.navbar-nav>li>a span.icon-busca {
  font-size: 18px;
}

#menu-principal {
  margin-right: -30px;
}

#menu-principal li.active a {
  color: #8cc63e;
}

.nav>li>a:focus, .nav>li>a:hover {
  background: transparent;
  text-decoration: none;
  color: #8cc63e;
}

#home-filtro a {
  margin-left: 5px;
  margin-bottom: 5px;
}

.nav>li>a:focus, .nav>li>a:hover span.icon-busca {
  background: transparent;
  text-decoration: none;
  color: #FFF;
  -webkit-text-shadow: 0 0 20px #78D003;
  -moz-text-shadow: 0 0 20px #78D003;
  text-shadow: 0 0 20px #78D003;
}

.sub-menu {
  padding:0;
  margin-left: 10px;
  list-style: none;
  // display: none;
}

.sub-menu a {
  color: #666 !important;
  font-size:16px;
  font-weight: 300;
  padding-top: 7px;
    padding-bottom: 7px;
    display: inline-block;
}

.sub-menu a:hover {
  text-decoration: none;
}

.sub-menu li.active a {
  font-weight: 700;
}

// .sub-menu .active.menu-esportes a, .sub-menu .menu-esportes a,
// .sub-menu .active.menu-entretenimento a, .sub-menu .menu-entretenimento a,
// .sub-menu .active.menu-celebridades a, .sub-menu .menu-celebridades a,
// .sub-menu .active.menu-consultoria a, .sub-menu .menu-consultoria a, {
//  font-weight:900;
//  font-size: 21px;
// }

.sub-menu li.active .sub-menu li a,
.sub-menu li .sub-menu li a {
  font-weight:300;
  font-size:16px;
}

.sub-menu li.active .sub-menu li.active a,
.sub-menu li .sub-menu li.active a {
  font-weight:700;
  font-size:16px;
}

.sub-menu .sub-menu {
  margin-left: 25px;
}

.sub-menu li a span {
  width: 22px;
  text-align: center;
  display: inline-block;
}

#menu-principal li:hover .sub-menu {
  display:block;
}

.repsubmenu {
  height: 100px;
  float: left;
  background: #8cc63e;
  width: 100%;
  margin-top: -4px;
}

.brand {
  display: block;
  width: 280px;
  height: 30px;
  font-size: 0;
  margin-top: 34px;
  color: transparent;
  background: url('../images/logo-ibope-repucom.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#principal {
  background: #FFF;
  // padding-top:95px;
  // padding-bottom:125px;
}

.navbar-toggle {
  margin-right: 0px;
    padding: 5px 0 5px 15px;
    margin-top: 62px;
}

.btn-menu-mobile {
  display:block;
  background:#8CC63E;
  height:3px;
  width:25px;
  margin: 6px 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.navbar-form {
  box-shadow:none;
  clear: both;
}

.linha-veja-tambem {
  display:block;
  height:1px;
  border-bottom:1px solid #2d3232;
  width:500px;
  position:absolute;
  top: 38px;
  left: -500px;

}


@media (max-width: $screen-sm-max) {
  #menu-principal {
    margin-right: 0;
    margin-left: -30px;
    float: left !important;
  }
  .navbar-nav {
    margin-top: 10px;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
  .container-fluid {
    padding-left:0;
    padding-right:0;
  }
  // .sub-menu .active.menu-esportes a, .sub-menu .menu-esportes a,
  // .sub-menu .active.menu-entretenimento a, .sub-menu .menu-entretenimento a,
  // .sub-menu .active.menu-celebridades a, .sub-menu .menu-celebridades a,
  // .sub-menu .active.menu-consultoria a, .sub-menu .menu-consultoria a, {
  //  font-weight:900;
  //  font-size: 17px;
  // }
  .sub-menu>li.dropdown>a {
    font-weight: 900;
    font-size: 17px !important;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max){
  // .sub-menu .active.menu-esportes a, .sub-menu .menu-esportes a,
  // .sub-menu .active.menu-entretenimento a, .sub-menu .menu-entretenimento a,
  // .sub-menu .active.menu-celebridades a, .sub-menu .menu-celebridades a,
  // .sub-menu .active.menu-consultoria a, .sub-menu .menu-consultoria a, {
  //  font-weight:900;
  //  font-size: 18px;
  // }
  .sub-menu>li.dropdown>a {
    font-weight: 900;
    font-size: 19px !important;
  }
}

.navbar-collapse.collapse {
  margin-bottom: 20px;
}

@media (max-width: $screen-xs-max) {
  h1 {font-size: 37px;}
  .banner {
    height: inherit !important;
    background: #8cc63e;
  }
  h2 {font-size: 34px;}
  p, button {font-size:15px;}
  // .banner {height: 125px;padding-left:15px;padding-right:15px;}
  .brand {width: 185px;height: 20px;margin-top: 70px;}
  .navbar-collapse {position: relative;z-index: 10;width: 100%;float: left;margin-top: 0;margin-bottom: 0;border-top:none;box-shadow: none; padding: 0 20px;}
  .navbar-nav>li>a {font-weight: 300;color: #2D3232;font-size: 17px;}
  #menu-principal {padding-top: 25px;padding-bottom: 25px;}
  .linha-veja-tambem {display: none;}
  .modal-title { font-size: 35px; }
}

.navbar-collapse {
  // background: #8cc63e;
}

/*
***********************************
FOOTER
***********************************
*/

.content-info {
  background: #8cc63e;
  padding-top: 70px;
  padding-bottom: 55px;
}

.content-info ul {
  list-style: none;
  padding-left: 0;
}

.links-footer li a {
  font-weight: 300;
  font-size: 17px;
  color: #2d3232;
  display: block;
  padding: 5px 0;
}

.links-footer li a:hover {
  text-decoration: none;
  opacity: 0.5;
}

.contem-button-sobe {
  width: 100%;
  text-align: center;
}

.button-sobe {
  margin: 0 auto;
  font-size: 94px;
  color: #2d3232;
}

.button-sobe:hover {
  text-decoration: none;
  color: #2d3232;
  opacity: 0.5;
}

.logo-footer {
  display: block;
  float: right;
  width: 215px;
  height: 22px;
  font-size: 0;
  margin-top: 0;
  color: transparent;
  background: url('../images/logo-ibope-repucom-footer.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.links-redes-sociais {
  display: block;
    float: right;
    width: 100%;
    text-align: right;
    margin-top: 48px !important;
}

.links-redes-sociais li {
  display: inline-block;
}

.links-redes-sociais li a {
  font-size: 19px;
  color: #2d3232;
  padding-left: 10px;
}

.links-redes-sociais li a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.direitos-reservados {
  font-size: 11px;
  margin-top: 55px;
  border-top: solid 1px #2d3232;
  padding: 5px 0;
}


@media (max-width: $screen-sm-max) {
  .direitos-reservados {margin-top: 25px;}
}

@media (max-width: $screen-xs-max) {
  .content-info {padding-top: 16px;padding-left: 10px;padding-right: 10px;}
  .direitos-reservados {margin-top: -35px !important;}
  .content-info ul {margin-top: 30px;margin-bottom: 55px;}
  .links-redes-sociais {margin-top: 18px !important;}
}


/*
***********************************
BUSCA HEADER
***********************************
*/

.contem-busca-rapida {
  background: #8cc63e;
  clear:both;
}

.btn-busca-rapida {
  background: #2d3232;
  color: #abacac;
  border-color: transparent !important;
  font-size: 17px;
  font-weight: 300;
  width: 110px;
  height: 46px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -30px;
  z-index: 999;
  position: relative;
}

.btn-busca-rapida:hover, .btn-busca-rapida:focus, .btn-busca-rapida:active, .btn-busca-rapida:visited {
  background: #2d3232;
  color: #adff00;
  border-color: #2d3232;
  -webkit-text-shadow: 0 0 15px #78D003;
  -moz-text-shadow: 0 0 15px #78D003;
  text-shadow: 0 0 15px #78D003;
  font-weight: 700;
  outline: none !important;
}

input.search-busca-rapida {
  font-size: 17px;
  font-weight: 300;
  width:100% !important;
  height: 46px;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
    box-shadow: none !important;
  -webkit-border-top-left-radius: 4px !important;
  -webkit-border-bottom-left-radius: 4px !important;
  -moz-border-radius-topleft: 4px !important;
  -moz-border-radius-bottomleft: 4px !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

input.search-busca-rapida::placeholder {
  font-style: Italic;
}

input.search-busca-rapida:focus {
  outline:!important;
  box-shadow: !important;
  border: none !important;
}

.icone-busca-rapida {
  font-size: 34px;
  margin-right: -15px;
  float: right;
  margin-top: 6px;
}

.inputgroup-busca-rapida {
  width: 100%;
  white-space: nowrap;
}

@media (max-width: $screen-xs-max) {
  input.search-busca-rapida {-webkit-border-radius: 4px !important;-moz-border-radius: 4px !important;border-radius: 4px !important;}
  .btn-busca-rapida {-webkit-border-radius: 4px !important;-moz-border-radius: 4px !important;border-radius: 4px !important;margin-left: 0;margin-top: 15px;}
}



/*
***********************************
BTN INFERIORES
***********************************
*/


.contem-btn-inferiores {
  background: #FFF;
  padding: 110px 0;
}

.btn-inferiores {
  width:100%;
  height:60px;
  background: #f4f4f4;
  display:block;
  line-height:60px;
  text-align:center;
  font-size:18px;
  font-weight:300;
  color:#818484;
  letter-spacing: -0.5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow:hidden;
}

.btn-inferiores span {
  margin-left:15px;
  color:#8cc63e;
  position: relative;
}

.btn-inferiores .icon-solicitar-orcamento {
  font-size:28px;
  top:6px;
}

.btn-inferiores .icon-entre-em-contato {
  font-size:34px;
  top:8px;
}

.btn-inferiores:hover {
  text-decoration:none;
  color:#666;
}

.btn-inferiores:hover span {
  color:#666;
}


@media (max-width: $screen-xs-max) {
  .btn-inferiores .icon-entre-em-contato {font-size:30px;top:8px;}
  .btn-inferiores .icon-solicitar-orcamento {font-size:22px;top:4px;}
  .btn-inferiores {margin-bottom: 30px;font-size: 15px;}
  .btn-inferiores span, .icon-entre-em-contato span {margin-left: 10px;}
}







/*
*********************************************************************************************************
*********************************************************************************************************
HOME
*********************************************************************************************************
*********************************************************************************************************
*/


/*
***********************************
FILTRAR POR
***********************************
*/

.filtrar-por {
  float: right;
  position: relative
}

.filtrar-por p {
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 400;
}

.filtrar-por ul {
  list-style: none;
  padding-left:0;
  margin-left:0;
}

.filtrar-por ul li {
  display: inline-block;
  text-align: center;
  position: relative;
}

.filtrar-por ul li a {
  display: inline-block;
  background: #eaeaea;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  height:50px;
  line-height: 50px;
  font-size: 17px;
  font-weight: 300;
  color: #959898;
  border: none;
  letter-spacing: 0.26px;
}

.filtrar-por ul li a.active,
.filtrar-por ul li a:hover {
  background: #2d3232;
  text-decoration: none;
  font-weight: 700;
  color: #8cc63e;
  outline: none;
  letter-spacing: 0px;
}

.filtrar-por ul li a:focus, .filtrar-por ul li a.ativado {
  background: #2d3232;
  letter-spacing: 0px;
  text-decoration: none;
  font-weight: 700;
  color: #adff00;
  -webkit-text-shadow: 0 0 15px #78D003;
  -moz-text-shadow: 0 0 15px #78D003;
  text-shadow: 0 0 15px #78D003;
  outline: none;
}

// .filtrar-por ul li button.filtrar-esportes {
//  width: 132px;
// }
// .filtrar-por ul li button.filtrar-entreternimento {
//  width: 165px;
// }
// .filtrar-por ul li button.filtrar-tudo {
//  width: 100px;
// }
.filtrar-por ul li a {
  padding-left: 20px;
  padding-right: 20px;
}

.contem-sub-filtro {
  width: 100%;
  padding: 0 15px;
}

.sub-filtrar-por {
  display: block;
  float: left;
  width: 100%;
  background: #2d3232;
  list-style: none;
  margin-left: 0;
  padding: 28px 20px 34px 20px;
  color: #FFF;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
}

.sub-filtrar-por li {
  padding: 2px 20px;
}

.sub-filtrar-por li button {
  width: 100%;
  display: block;
  text-align: left;
  background: transparent;
    border: none;
  border-bottom: 1px solid #565b5b;
  padding: 0;
}

.sub-filtrar-por li button span {
  display: inline-block;
  margin-right: 2px;
  text-align: center;
  width: 15px;
}

.sub-filtrar-por li button:hover, .sub-filtrar-por li button:focus {
  outline: none;
  color: #8cc63e;
}

.barra-filtro {
  width:8px;
  height:0;
  background:#2d3232;
  display:block;
  position: absolute;
  top: 48px;
  left: 50%;
  margin-left: -4px;
}

.fechar-subfiltro {
  position: absolute !important;
  right: 10px;
  top: 10px;
  width: 10px;
  height: 10px;
  padding: 0;
  font-size: 10px;
  background: transparent;
  border: none;
  color: #CCC;
  overflow: hidden;
}

.fechar-subfiltro:hover, .fechar-subfiltro:focus {
  outline: none;
  color: #8cc63e;
  position: absolute !important;
}

#subfiltro-esportes {
  height:0;
  overflow:hidden;
  position:relative;
  margin-top: -6px;
}

@media (max-width: $screen-xs-max) {
  .filtrar-por, #subfiltro-esportes {display:none;}
}



/*
***********************************
DESTAQUES
***********************************
*/

.coluna-esquerda {
  padding-top:15px;
}

.contem-destaques .col-lg-1, .contem-destaques .col-lg-2, .contem-destaques .col-lg-3, .contem-destaques .col-lg-4,
.contem-destaques .col-lg-5, .contem-destaques .col-lg-6, .contem-destaques .col-lg-7, .contem-destaques .col-lg-8,
.contem-destaques .col-lg-9, .contem-destaques .col-lg-10, .contem-destaques .col-lg-11, .contem-destaques .col-lg-12,
.contem-destaques .col-md-1, .contem-destaques .col-md-2, .contem-destaques .col-md-3, .contem-destaques .col-md-4,
.contem-destaques .col-md-5, .contem-destaques .col-md-6, .contem-destaques .col-md-7, .contem-destaques .col-md-8,
.contem-destaques .col-md-9, .contem-destaques .col-md-10, .contem-destaques .col-md-11, .contem-destaques .col-md-12,
.contem-destaques .col-sm-1, .contem-destaques .col-sm-2, .contem-destaques .col-sm-3, .contem-destaques .col-sm-4,
.contem-destaques .col-sm-5, .contem-destaques .col-sm-6, .contem-destaques .col-sm-7, .contem-destaques .col-sm-8,
.contem-destaques .col-sm-9, .contem-destaques .col-sm-10, .contem-destaques .col-sm-11, .contem-destaques .col-sm-12,
.contem-destaques .col-xs-1, .contem-destaques .col-xs-2, .contem-destaques .col-xs-3, .contem-destaques .col-xs-4,
.contem-destaques .col-xs-5, .contem-destaques .col-xs-6, .contem-destaques .col-xs-7, .contem-destaques .col-xs-8,
.contem-destaques .col-xs-9, .contem-destaques .col-xs-10, .contem-destaques .col-xs-11, .contem-destaques .col-xs-12 {
  padding-left: 1px;
    padding-right: 1px;
}

.coluna-dupla {
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 2px;
  width: 66.6666666667%;
  float: left;
  position: relative;
  min-height: 1px;
}

.coluna-unica {
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 2px;
  width: 33.3333333333%;
  float: left;
  position: relative;
  min-height: 1px;
}


.img-responsive-full {
  width: 100%;
  max-width: 100%;
}

.contem-destaques {
  margin-top: 20px;
  margin-left: -1px;
  margin-right: -1px;
}

.links-destaque:hover {
  text-decoration: none;
}
.links-destaque {
  display:block;
  overflow: hidden;
  position: relative;
  margin-bottom: 2px;
  text-decoration: none;
}

.links-destaque img {
  width: 100%;
  max-width: 100%;
  z-index: 1;
  border: none;
}

.destaque-box {
  // position: absolute;
  // left: 0;
  // bottom:0;
  display:block;
  width:100%;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px 40px 20px 30px;
}

.destaque-box span {
  position: absolute;
  color:#8cc63e;
  right: 10px;
  bottom: 10px;
  font-size: 22px;
}


.destaque-titulo, .destaque-desc {
  margin: 0;
  font-size: 17px;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
}

.destaque-titulo {
  font-size: 17px;
  line-height: 21px;
  font-weight: 700;
  color:#8cc63e;
  // white-space: nowrap;
}

.ver-todos, .ver-todos-dentro a {
  display: block;
  font-weight: 700;
  color: #8cc63e;
  font-size: 17px;
  margin:auto;
  width:215px;
  margin-bottom: 30px;
  text-align: center;
  clear: both;
  text-decoration: underline;

}

.contem-ver-todos {
  margin-top:30px;
  text-align: center;
}

.ver-todos:hover, .ver-todos-dentro a:hover {
  color: #8cc63e;
  opacity:0.7;
}

@media (max-width: $screen-md-max) {
  .destaque-box {padding: 20px 35px 20px 30px;}
  .destaque-titulo, .destaque-desc {font-size: 15px;}
}

@media (max-width: $screen-sm-max) {
  .destaque-box span {font-size:17px;}
  .coluna-dupla, .coluna-unica {width: 50%;}
}

@media (max-width: $screen-xs-max) {
  .coluna-dupla, .coluna-unica {width: 100%;}
  .links-destaque {height: 230px;}
  .links-destaque img {width: 130%;max-width: 130%;}
}



.destaque-banner-home h2 {
  position:relative;
  font-size:35px;
  color:#2d3232;
  text-align:left;
  display:block;
  background:#fff;
  padding:32px 42px 35px;
  margin:0;
}

.destaque-banner-home p {
  position:relative;
  color:#8cc63e;
  text-align:right;
  display:block;
  background:#2d3232;
  padding:32px 32px 35px;
  margin:0;
  letter-spacing: -0.2px;
}

.triangle-topright {
  position:absolute;
  left:15px;
  bottom:-60px;
  display:block;
  width: 0;
  height: 0;
  border-top: 60px solid #FFF;
  border-left: 80px solid transparent;
}

.triangle-topleft {
  position:absolute;
  right:15px;
  bottom:-60px;
  display:block;
  width: 0;
  height: 0;
  border-top: 60px solid #2d3232;
  border-right: 80px solid transparent;
}


@media (max-width: $screen-sm-max) {
  .destaque-banner-home h2 {font-size: 31px;}
}

@media (max-width: $screen-xs-max) {
  .destaque-banner-home h2 {font-size: 19px;letter-spacing: 0;padding: 27px;margin-bottom: 60px;}
  .destaque-banner-home p {font-size: 15px;line-height: 18px;padding: 27px;letter-spacing: 0;}
  .triangle-topleft {bottom: -40px;border-top: 40px solid #2d3232;border-right: 65px solid transparent;}
  .triangle-topright {bottom: -40px;border-top: 40px solid #FFF;border-left: 65px solid transparent;}
}


/*
***********************************
DESTAQUES - COLUNA DIREITA
***********************************
*/
.coluna-direita h2 {
  background-color: #8CC63E;
  display: block;
  color: #FFF;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0 0 0 15px;
  margin-top: 17px;
  margin-left: -40px;
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-bottom: 40px;
  -webkit-border-top-right-radius: 18px;
  -webkit-border-bottom-right-radius: 18px;
  -moz-border-radius-topright: 18px;
  -moz-border-radius-bottomright: 18px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.coluna-direita {
  position:relative;
  border-left: 1px dashed #000;
  padding: 0 0 0 40px;
  margin-left: 15px;
}

.coluna-direita a {
  display:block;
  font-weight:700;
  color:#2d3232;
  font-size:17px;
  margin-bottom: 30px;
  clear:both;
}

.coluna-direita a p {
  margin: 0;
  font-weight:700;
}

.coluna-direita a .destaque-data {
  color:#8cc63e;
  font-weight:300;
  margin-bottom: 0;
}

.coluna-direita a:hover {
  opacity:0.5;
  text-decoration: none;
}

.coluna-direita a.ver-todos {
  color:#8cc63e;
  text-decoration:underline;
  text-align:left;
}

.coluna-direita a.ver-todos:hover {
  opacity:0.8
}

.tracitop {
  width:6px;
  height:1px;
  background:#000;
  position:absolute;
  top:0;
  left:-3px;
}

.tracibot {
  width:6px;
  height:1px;
  background:#000;
  position:absolute;
  left:-3px;
  bottom:0;
}

.estudos-exclusivos {
  padding-left:15px;
  margin-top:96px;
}

.estudos-exclusivos a {
  display:block;
  width:100%;
  padding: 35px 0 28px 0;
  background:#8cc63e;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color:#FFF;
  text-align:center;
  font-size: 17px;
  font-weight:300;
}

.estudos-exclusivos a:hover {
  opacity: 0.8;
  text-decoration:none;
}

.estudos-exclusivos a .estrela-menor {
  font-size:13px;
}

.estudos-exclusivos a .estrela-maior {
  font-size:18px;
}


.top-celebridades {
  padding-left:15px;
  margin-top:20px;
}

.top-celebridades a {
  display:block;
  position:relative;
  overflow:hidden;
  width:100%;
  padding: 75px 32px 12px 0;
  background:#2d3232;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color:#FFF;
  text-align: right;
  font-size: 17px;
  font-weight:300;
}

.top-celebridades a .topceleb {
  font-weight:900;
  font-size:105px;
  letter-spacing: -12px;
  position: absolute;
  line-height: 105px;
  top: -25px;
  left: -2px;
}

.top-celebridades a .icon-celebridades {
  position: absolute;
  color: #8cc63e;
  font-size: 38px;
  top: 22px;
  left: 34px;
  border-radius: 50%;
  background-color: #FFF;
}

.estudos-exclusivos a:hover, .top-celebridades a:hover {
  opacity: 0.8;
  text-decoration:none;
}

.estudostop {
  display:none;
  position:relative;
  top: 22.5px;
  height:3px;
  width:100%;
  border-top: 1px #2d3232 dashed;
  z-index:1;
}

.tracileft {
  width:1px;
  height:6px;
  background:#000;
  position:absolute;
  top:-3px;
  left:0;
}

.traciright {
  width:1px;
  height:6px;
  background:#000;
  position:absolute;
  top:-3px;
  right:0;
}

@media (max-width: $screen-md-max) {
  .estudos-exclusivos a {padding: 33px 0 25px 0;font-size: 15px;}
  .top-celebridades a {font-size:15px;letter-spacing:-0.5px;padding-right:15px;padding-bottom: 5px;}
}

@media (max-width: $screen-sm-max) {
  .coluna-direita {border:none;padding:0 52px;margin-left: 0;margin-top: 55px;text-align: center;}
  .tracitop, .tracibot {display: none;}
  .estudostop {display: block;}
  .coluna-direita h2 {z-index: 69;position: relative;margin: 0 auto;text-align: center;width: 340px;padding: 0;
  -webkit-border-radius: 18px;-moz-border-radius: 18px;border-radius: 18px;float:none;margin-bottom: 50px;}
  .coluna-direita a p {margin: 0;text-align: left;}
  .coluna-direita a.ver-todos {padding-top: 20px;}
  .estudos-exclusivos, .top-celebridades {padding:0;margin-top: 60px;}
  .estudos-exclusivos {margin-right: -10px;}
  .top-celebridades {margin-left: -10px;}
}

@media (max-width: $screen-xs-max) {
  .coluna-direita {padding:0;}
  .coluna-direita h2 {width: 148px;}
  .estudos-exclusivos {margin-right: 0;}
  .top-celebridades {margin-left: 0;margin-top: 14px;}
}



/*
***********************************
BANNER SOLUÇÕES
***********************************
*/

.ajuste-bannerlinks .col-lg-1, .ajuste-bannerlinks .col-lg-2, .ajuste-bannerlinks .col-lg-3, .ajuste-bannerlinks .col-lg-4,
.ajuste-bannerlinks .col-lg-5, .ajuste-bannerlinks .col-lg-6, .ajuste-bannerlinks .col-lg-7, .ajuste-bannerlinks .col-lg-8,
.ajuste-bannerlinks .col-lg-9, .ajuste-bannerlinks .col-lg-10, .ajuste-bannerlinks .col-lg-11, .ajuste-bannerlinks .col-lg-12,
.ajuste-bannerlinks .col-md-1, .ajuste-bannerlinks .col-md-2, .ajuste-bannerlinks .col-md-3, .ajuste-bannerlinks .col-md-4,
.ajuste-bannerlinks .col-md-5, .ajuste-bannerlinks .col-md-6, .ajuste-bannerlinks .col-md-7, .ajuste-bannerlinks .col-md-8,
.ajuste-bannerlinks .col-md-9, .ajuste-bannerlinks .col-md-10, .ajuste-bannerlinks .col-md-11, .ajuste-bannerlinks .col-md-12,
.ajuste-bannerlinks .col-sm-1, .ajuste-bannerlinks .col-sm-2, .ajuste-bannerlinks .col-sm-3, .ajuste-bannerlinks .col-sm-4,
.ajuste-bannerlinks .col-sm-5, .ajuste-bannerlinks .col-sm-6, .ajuste-bannerlinks .col-sm-7, .ajuste-bannerlinks .col-sm-8,
.ajuste-bannerlinks .col-sm-9, .ajuste-bannerlinks .col-sm-10, .ajuste-bannerlinks .col-sm-11, .ajuste-bannerlinks .col-sm-12,
.ajuste-bannerlinks .col-xs-1, .ajuste-bannerlinks .col-xs-2, .ajuste-bannerlinks .col-xs-3, .ajuste-bannerlinks .col-xs-4,
.ajuste-bannerlinks .col-xs-5, .ajuste-bannerlinks .col-xs-6, .ajuste-bannerlinks .col-xs-7, .ajuste-bannerlinks .col-xs-8,
.ajuste-bannerlinks .col-xs-9, .ajuste-bannerlinks .col-xs-10, .ajuste-bannerlinks .col-xs-11, .ajuste-bannerlinks .col-xs-12 {
  padding-left: 1px;
    padding-right: 1px;
}

.contem-solucoes-bannerlinks {
  margin-top:20px;
}

.bannerlinks-titulo {
  text-align:center;
  margin-bottom: 30px;
}

.contem-solucoes-bannerlinks .container-fluid {
  background: #f4f4f4;
}

.solucao-bannerlinks {
  position:relative;
  width:100%;
  height:620px;
  background:#2d3232;
  color:#FFF;
  text-align:center;
}

.sblinks-txt {
  position:absolute;
  top: 50%;
  margin-top: -78px;
  text-align:center;
  width:100%;
}

.sblinks-txt p {
  font-size:30px;
  font-weight:700;
}

.sblinks-txt span {
  font-size:100px;
}

.sblinks-img {
  position:relative;
  top:0;
  width:100%;
  height:100%;
  background-position: center bottom;
  background-size:cover;
  opacity:0.5;
}

.sblinks-sub {
  display: none;
  width: 100%;
  height: auto;
  list-style: none;
  margin: 0;
  padding: 40px;
  position: relative;
  margin-top:144px;
}

.sblinks-sub li {
  border-bottom: 1px solid #FFF;
  padding: 3px 0;
}

.sblinks-sub li:last-child {
  border:none;
}

.sblinks-sub li:only-child {
  border-bottom: 1px solid #FFF;
}

.sblinks-sub li a {
  color:#FFF;
  font-size:17px;
  font-weight:300;
}

.sblinks-sub li a:hover {
  text-decoration:none;
  opacity:0.7;
}

.solucao-bannerlinks:hover .sblinks-img {
  height:210px;
  opacity:1;
}

.solucao-bannerlinks:hover .sblinks-txt {
  color:#8cc63e;
  margin-top: -50px;
}

.solucao-bannerlinks:hover .sblinks-sub {
  display:block;
}

.solucao-bannerlinks:hover .sblinks-txt span {
  font-size:70px;
}

@media (max-width: $screen-sm-max) {
  .solucao-bannerlinks {margin-bottom: 2px;}
}

@media (max-width: $screen-xs-max) {
  .solucao-bannerlinks {margin-bottom: 20px;}
}




/*
*********************************************************************************************************
*********************************************************************************************************
QUEM SOMOS
*********************************************************************************************************
*********************************************************************************************************
*/


/*
***********************************
NOSSOS VALORES
***********************************
*/

.icones-comp {
  text-align:center;
  margin-bottom: 20px;
}

.icones-comp h2 {
  font-size:42px;
  font-weight:100;
  letter-spacing:-2px;
}

.icones-comp h4 {
  font-size:21px;
  font-weight:900;
}

.contem-icones-center {
  display:inline-block;
  clear:both;
}

.icones-verde-longshadow {
  text-align:center;
  width:130px;
  display: inline-table;
  margin: 10px 15px;
}

.icones-verde-longshadow span {
  display:table-cell;
  vertical-align:middle;
  line-height:102px;
}

.icones-verde-longshadow .icon-respeito,
.icones-verde-longshadow .icon-foco,
.icones-verde-longshadow .icon-bola-volei,
.icones-verde-longshadow .icon-bola-basquete,
.icones-verde-longshadow .icon-bola-futebol,
.icones-verde-longshadow .icon-tempo {
  font-size: 58px;
}



.icones-verde-longshadow .icon-excelencia {
  font-size: 30px;
}

.icones-verde-longshadow p {
  font-size:17px;
  font-weight:300;
  color:#2d3232;
  padding-top: 15px;
  line-height: 18px;

}

@media (max-width: $screen-sm-max) {
  .icones-verde-longshadow {margin:10px 5px;}
  .destaque-box {
    position: absolute;
    left: 0;
    bottom:0;
  }
}

@media (max-width: $screen-xs-max) {
  .icones-comp h2 {font-size:28px;}
  .icones-verde-longshadow {margin:10px -3px;}
}


/*
***********************************
GESTÃO / LIDERANÇA
***********************************
*/

#gestao-lideranca {
  margin-top:50px;
}

#gestao-lideranca h1 {
  margin-bottom: 65px;
}

.gestao-lid-nome {
  font-weight:700;
  font-size:25px;
  margin-bottom: 0;
}

.gestao-lid-cargo {
  font-style:Italic;
  margin-top: 2px;
  margin-bottom: 35px;
}


.gestao-lid-email {
  color:#8cc63e;
  margin-top:35px;
  display:block;
}

.gestao-lid-email:hover {
  color:#8cc63e;
}

@media (max-width: $screen-xs-max) {
  #gestao-lideranca h1, .gestao-lid-nome, .gestao-lid-cargo {text-align: center;  }
  .gestao-lid-nome {
    margin-top: 20px;
  }
}




/*
*********************************************************************************************************
*********************************************************************************************************
BANNER's
*********************************************************************************************************
*********************************************************************************************************
*/


/*
***************************************************
BANNER CENTER TXT (com texto centralizado)
***************************************************
*/

.banner-center-txt {
  position:relative;
  display:block;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 70px;
  background-color:#2D3232;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  clear:both;
}

.banner-center-solucoes {
  position:relative;
  display:block;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 70px;
  background-color:rgba(45, 50, 50, 0.05);
  clear:both;
}

.banner-center-conteudo {
  display:table;
  width:100%;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 420px;
}

.banner-conteudo {
  display: table-cell;
    vertical-align: middle;
    text-align:center;
    color:#fff;
  padding-left: 15px;
  padding-right: 15px;
}

.banner-conteudo span {
  font-size:100px;
}

.banner-conteudo a, .banner-conteudo a:hover {
  color:#FFF;
  text-decoration: underline;
}

.allcenter {
  text-align: center !important
}

.indica-scroll {
  font-size: 72px;
  color: #FFF;
  width: 72px;
  height: 90px;
  position: absolute;
  top: 50%;
  margin-top: -45px;
  right: 50px;
}

.indica-scroll span {
  text-shadow: 0 0 15px rgba(0, 0, 0, 0.4)
}


@media (max-width: $screen-xs-max) {
  .banner-conteudo {
    text-align: left;
  }
}




/*
*********************************************************************************************************
*********************************************************************************************************
SOLUÇÔES
*********************************************************************************************************
*********************************************************************************************************
*/

.contem-box-interno {
  margin-bottom:32px;
}

.icones-box-duplo {
  color:#8cc63e;
  height:405px;
  text-align:center;
  border: 1px solid #8cc63e;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 122px 30px 0;
  z-index:1;
}

.icones-box-duplo:hover {
  background: rgba(0,0,0,0.1);
  cursor:pointer;
}

.icones-box-duplo span {
  font-size:95px;
}

.icones-box-duplo-interno {
  display:none;
  position:relative;
  min-height:405px;
  top: -405px;
  left:0;
  color:#2D3232;
  background:#8cc63e;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 35px 32px 20px;
  z-index:69;
}

.icones-box-duplo-interno span {
  font-size: 54px
}

.icones-box-duplo-interno button {
  position:absolute !important;
  top:10px;
  right:10px;
  background: transparent;
  border: none;
}

.icones-box-duplo-interno button span {
  font-size:20px;
}

.icones-box-duplo-interno button:hover,
.icones-box-duplo-interno button:active,
.icones-box-duplo-interno button:focus,
.icones-box-duplo-interno button:visited {
  outline:none;
  opacity:0.5;
}

.icones-box-duplo-interno ul {
  padding: 10px 0 0 18px;
}

.icones-box-duplo-interno ul li {
  margin-bottom: 15px;
  font-size:17px;
  font-weight:300;
}


@media (max-width: $screen-xs-max) {
  .icones-box-duplo {
    height: 250px;
    padding-top:60px;
  }
  .icones-box-duplo-interno {
    top:-250px;
  }
}





.icones-veja-tambem {
  position:relative;
  display:inline-block;
  margin: 35px;
  height: 260px;
}

.icones-veja-tambem:hover {
  opacity:0.8;
}

.icones-veja-tambem div {
  display: block;
  width: 130px;
  height: 130px;
  background-color: #2d3232;
  color: #8cc63e;
  font-size: 20px;
  text-align: left;
  padding: 10px;
}

.veja-tambem-circulo {
  position: relative;
  display:table;
  width:260px;
  height:260px;
    top: -130px;
    left: 0;
    z-index: 69;
  background-color:#2D3232;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-position:center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.veja-tambem-circulo {
  display:table-cell;
  vertical-align:middle;
  font-size:26px;
  font-weight:900;
  color:#FFF;
  letter-spacing:0;
}

.veja-tambem-circulo:hover {
  text-decoration:none;
  color: #FFF !important;
}


@media (max-width: $screen-md-max) {
  .icones-veja-tambem {
    margin: 20px;
    height: 200px;
  }
  .icones-veja-tambem span {
    padding: 7px;
    width: 100px;
    height: 100px;
  }
  .veja-tambem-circulo {
    width: 200px;
    height: 200px;
    top: -100px;
  }
  .veja-tambem-circulo h3 {
    font-size:23px;
  }
}

@media (max-width: $screen-sm-max) {
  .icones-veja-tambem {
    margin: 7.5px;
    height: 200px;
  }
}

@media (max-width: $screen-sm-max) {
  .icones-veja-tambem {
    margin: 25px;
  }
}



.remove-p p {
  margin: 0;
}

.icone-center-grande span {
  font-size: 135px;
  color: #8cc63e;
}



.icones-box-fundo {
  display:inline-block;
  width:220px;
  min-height:376px;
  margin: -3px -2px;
    overflow: hidden;
    padding: 50px 25px;
}

.icones-box-fundo span {
  font-size:118px;
  color: #8cc63e;
}

.icones-box-fundo p {
  margin-top:20px;
}

.icones-box-fundo:nth-child(even) {
  background: #eeeeee
}
.icones-box-fundo:nth-child(odd) {
  background: #f4f4f4
}

.removebg {
  background:transparent !important;
}

@media (max-width: $screen-xs-max) {
  .navbar-header {
    padding: 0 20px 20px;
  }
  .icones-box-fundo {
    display: inline-block;
    width: 50%;
    height: 300px;
    margin: -2px;
    padding: 30px 14px;
  }
  .icones-box-fundo span {
    font-size:74px;
  }
  .icones-box-fundo p {
    font-size: 15px;
  }
}





.icones-bigtxt {
  display:inline-table;
  width: 315px;
  margin: 0 50px 30px;
  clear:both;
}

.icones-bigtxt span {
  font-size: 90px;
}

@media (max-width: $screen-sm-max) {
  .icones-bigtxt {
    display:inline-table;
    width: 300px;
    margin: 0 24px 50px;
    clear:both;
  }
}

@media (max-width: $screen-xs-max) {
  .icones-bigtxt {
    display:inline-table;
    width: 100%;
    margin: 0 0 50px 0;
    clear:both;
  }
}



.banner-quatro-box {
  display:block;
  margin-left:-15px;
  margin-right:-15px;
  clear:both;
  min-height: 620px;
    margin-bottom: 50px;
}

.banner-quatro-box div {
  width:50%;
  height:310px;
  float:left;
  background-color:#ccc;
  background-position:center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}

.banner-quatro-box div div {
  width:100%;
  height:100%;
  position:relative;
  overflow:hidden;
}

.banner-quatro-box div div h2 {
  letter-spacing:0;
  width:470px;
}

.banner-quatro-box div div.bqb-left-top,
.banner-quatro-box div div.bqb-right-bottom {
  background:rgba(45, 50, 50, 0.8);
}

.banner-quatro-box div div.bqb-right-top,
.banner-quatro-box div div.bqb-left-bottom {
  background:rgba(140, 198, 62, 0.9);
}

.banner-quatro-box div div.bqb-left-top h2 {
  position: absolute;
  bottom: 40px;
  right: 65px;
  text-align:right;
}

.banner-quatro-box div div.bqb-right-top h2 {
  position: absolute;
  bottom: 40px;
  left: 65px;
  text-align:left;
}

.banner-quatro-box div div.bqb-left-bottom h2 {
  position: absolute;
  top: 25px;
  right: 65px;
  text-align:right;
}

.banner-quatro-box div div.bqb-right-bottom h2 {
  position: absolute;
  top: 25px;
  left: 65px;
  text-align:left;
}

.bqb-txt-extra {
  color: #8cc63e;
  font-size: 67px;
}

.bqp-txt-grande-bold {
  font-size:42px;
  color:#FFF;
  font-weight:900;
}

.bqp-txt-medio {
  font-size:32px;
  color:#FFF;
}



@media (max-width: $screen-md-max) {
  .bqb-txt-extra {font-size:52px;}
  .bqp-txt-grande-bold {font-size:32px;}
  .bqp-txt-medio {font-size:24px;}
  .banner-quatro-box div div h2 {width:365px;}
  .banner-quatro-box div div.bqb-right-bottom h2 {font-weight: 300;}
}

@media (max-width: $screen-sm-max) {
  .banner-quatro-box {min-height: 372px;}
  .banner-quatro-box div {height:186px;}
  .bqb-txt-extra {font-size:40px;}
  .bqp-txt-grande-bold {font-size:27px;}
  .bqp-txt-medio {font-size:22px;}
  .banner-quatro-box div div h2 {width:325px;}
  .banner-quatro-box div div.bqb-left-top h2 {bottom: 25px;right: 40px;}
  .banner-quatro-box div div.bqb-right-top h2 {bottom: 25px;left: 40px;}
  .banner-quatro-box div div.bqb-left-bottom h2 {top: 12px;right: 40px;}
  .banner-quatro-box div div.bqb-right-bottom h2 {top: 12px;left: 40px;font-weight: 300;}
}

@media (max-width: $screen-xs-max) {
  .banner-quatro-box {min-height:800px;}
  .banner-quatro-box div {width: 100%;height:195px;}
  .quatrobox03 {margin-top: 195px;}
  .quatrobox04 {margin-top: -390px;}
  .banner-quatro-box div div h2 {width:100%;padding: 0 30px;}
  .banner-quatro-box div div.bqb-left-top h2 {bottom: 25px;right: 0;}
  .banner-quatro-box div div.bqb-right-top h2 {bottom: 25px;left: 0;}
  .banner-quatro-box div div.bqb-left-bottom h2 {top: 12px;right: 0;}
  .banner-quatro-box div div.bqb-right-bottom h2 {top: 12px;left: 0;}
}





.boxinbox {
  display: inline-block;
  width: 350px;
  height: 350px;
  background: #f4f4f4;
  margin: 3px;
  padding: 0 50px;
}

.boxinbox h3 {
  font-size: 27px;
  color: #8cc63e;
  letter-spacing: 0;
  white-space: nowrap;
  font-weight: 300;
}

.boxinbox span {
  font-size: 55px;
  color: #8cc63e;
}

.boxinbox-inter {
  display: table;
  width:100%;
  height:100%;
  padding-top: 68px;
}

.boxinbox-sub {
  display: table-cell;
  vertical-align: top;
}


@media (max-width: $screen-xs-max) {
  .boxinbox {width: 250px;height: 250px;margin: 3px;padding: 0 20px;}
  .boxinbox h3 {font-size: 22px;margin: 5px;}
  .boxinbox span {font-size: 45px;}
  .boxinbox-inter {padding-top: 42px;}
}





.boxincirclegreen {
  display: inline-block;
  width: 350px;
  height: 350px;
  background: #8cc63e;
  margin: 3px;
  padding: 0 50px;
  border-radius: 50%;
  color:#2d3232;
}

.boxincirclegreen h3 {
  font-size: 27px;
  color: #FFFFFF;
  letter-spacing: 0;
  // white-space: nowrap;
  font-weight: 300;
}

.boxincirclegreen span {
  font-size: 55px;
  color: #FFFFFF;
}

.boxincirclegreen-inter {
  display: table;
  width:100%;
  height:100%;
  padding-top: 68px;
}

.boxincirclegreen-sub {
  display: table-cell;
  vertical-align: top;
}


@media (max-width: $screen-xs-max) {
  .boxincirclegreen {width: 250px;height: 250px;margin: 3px;padding: 0 20px;}
  .boxincirclegreen h3 {font-size: 22px;margin: 5px;}
  .boxincirclegreen span {font-size: 45px;}
  .boxincirclegreen p {line-height:18px;}
  .boxincirclegreen-inter {padding-top: 42px;}
}




.boxincircle {
  display: inline-block;
  width: 350px;
  height: 350px;
  background: #2d3232;
  margin: 3px;
  padding: 0 50px;
  border-radius: 50%;
  color:#FFFFFF;
}

.boxincircle h3 {
  font-size: 27px;
  color: #8cc63e;
  letter-spacing: 0;
  white-space: nowrap;
  font-weight: 300;
}

.boxincircle span {
  font-size: 55px;
  color: #8cc63e;
}

.boxincircle-inter {
  display: table;
  width:100%;
  height:100%;
  padding-top: 68px;
}

.boxincircle-sub {
  display: table-cell;
  vertical-align: top;
}


@media (max-width: $screen-xs-max) {
  .boxincircle {width: 250px;height: 250px;margin: 3px;padding: 0 20px;}
  .boxincircle h3 {font-size: 22px;margin: 5px;}
  .boxincircle span {font-size: 45px;}
  .boxincircle p {line-height:18px;}
  .boxincircle-inter {padding-top: 42px;}
}




/*
*********************************************************************************************************
*********************************************************************************************************
INSIGHTS
*********************************************************************************************************
*********************************************************************************************************
*/

.total-insights {
  margin-top:65px;
}





.filtrar-por.padrao-tags {
  float:left;
  margin-top: 45px;
}

.filtrar-por.padrao-tags ul li {
  float: left;
  margin-right: 2px;
  margin-bottom: 2px;
}

.filtrar-por.padrao-tags ul li button {
  padding-left: 30px;
  padding-right: 30px;
  min-width: 100px;
}

.filtrar-por.padrao-tags ul li button.ativado,
.filtrar-por.padrao-tags ul li button:focus,
.filtrar-por.padrao-tags ul li button:hover {
  padding-left: 29.1px;
  padding-right: 29.1px;
}

@media (max-width: $screen-sm-max) {
  .filtrar-por.padrao-tags ul li button {
    padding-left: 20px;
    padding-right: 20px;
    min-width: 90px;
  }
  .filtrar-por.padrao-tags ul li button.ativado,
  .filtrar-por.padrao-tags ul li button:focus,
  .filtrar-por.padrao-tags ul li button:hover {
    padding-left: 19.1px;
    padding-right: 19.1px;
  }
}

@media (max-width: $screen-xs-max) {
  .filtrar-por.padrao-tags {display:block !important}
}



// #busca-estudos-form {
//  margin-left:-15px;
//  margin-right:-15px;
// }

input.search-busca-rapida.busca-estudos {
  border: 1px solid #a7a9ac !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
  padding-left:20px;
  padding-right: 52px;
  height:60px;
  margin-top:4px;
}

.icone-busca-estudos {
  font-size: 19px;
  color: #a7a9ac;
  float: right;
  margin-top: -40px;
  margin-right: 18px;
  position: relative;
  z-index: 69;
  background: none;
  border: none;
  padding: 0;
}





.contem-links-estudos {
  margin-top:90px;
}

.estudos-links {
  display:block;
  text-align:center;
  color:#2d3232;
  margin-bottom: 80px;
}

.estudos-links:hover {
  text-decoration:none;
}

.estudos-links:hover img {
  opacity:0.6;
}

.icones-estudos-links {
  display:block;
  position:relative;
  height:28px;
}

.icones-estudos-links span {
  color:rgba(140, 198, 62, 0.25);
  font-size:20px;
  position: relative;
    top: -4px;
}
.icones-estudos-links span.ativo {
  color:#8cc63e;
}

.icones-estudos-links span.icon-cadeado {
  font-size:28px;
  color:#2d3232;
  float:right;
  margin-right:30px;
  margin-left: -50px;
  top:0;
}

.img-estudos {
  display:table;
  position:relative;
  margin-top:5px;
  width:100%;
  height:198px;
  overflow:hidden;
  padding: 0 35px;
}

.img-estudos span {
  display:table-cell;
  vertical-align:middle;
}

.img-estudos span img {
  max-width:100%;
  max-height:198px;
}

.estudos-texto {
  font-size:26px;
  font-weight:100;
  line-height: 28px;
  margin-top: 28px;
  color:#2d3232;
  padding: 0 35px;
}

.estudos-ano {
  font-size:22px;
  font-weight:300;
  line-height: 28px;
  margin-top: 15px;
  color:#8cc63e;
}

@media (max-width: $screen-sm-max) {
  .icones-estudos-links {height:17px;}
  .icones-estudos-links span {font-size:13px;}
  .icones-estudos-links span.icon-cadeado {font-size:17px;}
  .img-estudos {height:120px;}
  .img-estudos span img {max-height:120px;}
  .estudos-texto {font-size:17px;font-weight:300;padding: 0 20px;line-height:20px;margin-top: 18px;}
  .estudos-ano {font-size:14px;font-weight:300;margin-top:-4px;}
}

@media (max-width: $screen-xs-max) {
  .estudos-links {height:140px;}
  .icones-estudos-links {text-align:left;}
  .estudos-links-left {display: block;width: 33%;float: left;}
  .estudos-links-right {display: table;width: 60%; height:100%; float: right;}
  .estudos-links-right span {display:table-cell;vertical-align:middle;}
  .estudos-texto {font-size: 15px; padding: 0;text-align:left;}
  .icones-estudos-links span.icon-cadeado {margin-right:0}
  .img-estudos {padding: 0;}
  .estudos-ano {text-align: left;}
}






/*=============================================
=            Página Estudos Aberta            =
=============================================*/
.estudo-open-marca {
  font-size:21px;
  margin-bottom: 35px;
}

.contem-pageflip {
  margin-top:60px;
  position:relative;
}

#download {
  margin-top: 50px;
}
.estudo-download-completo {
  height:35px;
  line-height:35px;
  font-size:17px;
  font-weight:700;
  color:#8cc63e;
  padding: 10px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: block-inline;
  background: transparent;
  border: 1px solid #8CC63E !important;
  margin-top: 50px;
}

.estudo-download {
  height:35px;
  font-size:17px;
  font-weight:300;
  color:#8cc63e;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #8CC63E !important;
  float:right;
  margin-top:25px;
  padding-left: 15px;
  padding-right: 15px;
}

.estudo-download:hover,
.estudo-download-completo:hover {
  color: #FFFFFF;
  background: #8CC63E;
  text-decoration: none;
}

.estudo-download span {
  font-size: 15px;
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.estudos-download-aviso {
  position:absolute;
  width:100%;
  height:100%;
  background:rgba(25, 25, 25, 0.8);
  top:0;
  left:0;
  padding: 0 20%;
  text-align: center;
}

.estudos-download-aviso h4 {
  font-size:37px;
  color:#8cc63e;
}

.estudos-download-aviso button {
  width:72px;
  height:72px;
  background:transparent;
  border:none;
  font-size:72px;
  color:#8cc63e;
  padding: 0;
  line-height: 72px;
}

@media (max-width: $screen-sm-max) {
  .estudos-download-aviso h4 {font-size:20px;}
  .estudos-download-aviso button {width: 45px;height: 45px;font-size: 45px;line-height: 45px;}
}

@media (max-width: $screen-xs-max) {
  .estudos-download-aviso h4 {font-size:15px;}
  .estudo-download {margin-bottom:60px;}
}




.estudos-detalhes h3 {
  font-size:36px;
  margin-top:75px;
}

.estudos-detalhes h4 {
  margin-top:24px;
}

.estudos-dados-form {
  margin-top:35px;
}

.estudos-dados-form input, .estudos-dados-form select {
  height:50px;
  color:#2d3232;
  border:1px solid #2d3232;
  font-size: 17px;
  font-weight: 300;
  text-indent: 7px;

}

// .estudos-dados-form input {
//  text-indent: 10px;
// }

.estudos-dados-form input::placeholder {
  color:#2d3232;
}

// .estudos-dados-form select {
//  background:rgba(0, 0, 0, 0.05);
// }

.estudos-dados-form .checkbox {
  margin-top:0;
}

.estudos-dados-form .checkbox label input[type=checkbox] {
  height:auto;
  cursor:pointer;
}

.estudos-dados-form .checkbox label span {
  font-size: 15px;
  font-weight: 300;
  margin-left: 8px;
  position: relative;
  top: 1px;
}

.estudos-dados-form button {
  height: 50px;
  float: right;
  background:#8cc63e;
  border:none;
  width:147px;
  font-size:17px;
  font-weight:300;
}

.estudos-dados-form button span {
  margin-left:5px;
  color:#FFF;
  font-size:21px;
}

.estudos-dados-form button:hover span {
  color:#8cc63e;
}

@media (max-width: $screen-xs-max) {
  .estudos-dados-form button {margin-top: 25px;}
}

/*=====  End of Página Estudos Aberta  ======*/





/*================================================
=            Página Insights Notícias            =
================================================*/
.linha-fina {
  font-weight: 900;
  font-size: 22px;
}
.ins-noticias-destaque {
  background:#2d3232;
  position:relative;
  display:block;
  width:100%;
  float:left;
  margin-top: 15px;
}

.ins-noticias-destaque-img {
  position:relative;
  overflow:hidden;
  width:70%;
  height:440px;
  float:left;
  background-color:transparent;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
}

.ins-noticias-destaque-img img {
  width:100%;
}

.ins-noticias-destaque-txt {
  position:relative;
  overflow:hidden;
  display:table;
  width:30%;
  height:440px;
  color:#FFF;
  float:left;
  padding: 28px 40px;
}

.ins-noticias-destaque-txt a:hover {
  color:inherit;
  text-decoration:none;
  opacity:0.7;
}

.ins-noticias-destaque-data {
  position:absolute;
  width: 100%;
  top:12px;
  right:10px;
  margin:0;
}

.ins-noticias-destaque-data p {
  position:absolute;
  top:-4px;
  right:30px;
  font-size:11px;
  font-weight:700;
  color:#8cc63e;
  margin:0;
  padding:0;
}

.ins-noticias-destaque-data span {
  position:absolute;
  top:0;
  right:0;
  font-size:20px;
  color:#8cc63e;
}

.ins-noticias-destaque-sub {
  display:table-cell;
  vertical-align:bottom;
  color:#FFF;
}

.ins-noticias-destaque-txt h3 {
  color:#8cc63e;
  font-size:36px;
}

.ins-noticias-destaque-txt h3 span {
  color:#FFF;
}


@media (max-width: $screen-md-max) {
  .ins-noticias-destaque-img {height:385px;width:65%;}
  .ins-noticias-destaque-txt {height:385px;width:35%;}
  .ins-noticias-destaque-txt h3 {font-size:32px;}
}

@media (max-width: $screen-sm-max) {
  .ins-noticias-destaque-img {height:365px;width:58%;}
  .ins-noticias-destaque-txt {height:365px;width:42%;padding: 12px 25px;}
  .ins-noticias-destaque-txt h3 {font-size:30px;letter-spacing:-1px;}
}

@media (max-width: $screen-xs-max) {
  .ins-noticias-destaque-img {height: 285px;width: 100%;}
  .ins-noticias-destaque-txt {height: auto;width: 100%;padding: 45px 25px 12px;}
}



.mais-noticias-list {
  margin-top: 60px;
  margin-bottom: 30px;
}

.mais-noticias-list span {
  width: 32px;
  height: 32px;
  background: #8CC63E;
  display: block;
  line-height: 32px;
  text-align: center;
  color:#fff;
  position:absolute;
  right:0;
  top:0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size:20px;
}

.mais-noticias-list h4 a {
  font-size:32px;
  margin-top:20px;
  color: #333;
  text-decoration: none;
}

.mais-noticias-list a:hover {
  text-decoration:none;
}

.mais-noticias-list p a {
  font-weight:700;
  color:#8cc63e;
  white-space: nowrap;
}

.mais-noticias-list p a:hover {
  text-decoration:underline;
}

.mais-noticias-list-data {
  overflow:hidden;
  position:relative;
  height:32px;
}

.mais-noticias-list-data p {
  position: relative;
  font-size: 11px;
  font-weight: 700;
  color:#8cc63e;
  text-transform:uppercase;
  letter-spacing: 5px;
  display: inline-block;
  margin-top:9px;
}

.mais-noticias-list-data p span {
  position: absolute;
  width:1000px;
  height:0;
  border-bottom:1px solid #8cc63e;
  top: 7px;
  right: -1015px;
}


@media (max-width: $screen-xs-max) {
  .mais-noticias-list-data p {letter-spacing:0;}
}


/*=====  End of Página Insights Notícias  ======*/





/*=======================================
=            Noticias Aberta            =
=======================================*/
.noticia-interna {
  margin-top:55px;
}

.noticia-interna-retranca {
  font-size:21px;
  height:20px;
  line-height:20px;
}

.noticia-interna-sub {
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  color: #8cc63e;
  margin-top: 15px;
  text-transform: uppercase;
}

.noticia-interna-sub span {
  font-size:20px;
  position: relative;
  top: 5px;
  margin-right: 4px;
}

.noticia-interna-titulo {
  font-weight:900;
  font-size:46px;
  margin-top: 5px;
  // padding-right: 180px;
}

.noticia-interna-legenda {
  font-style:Italic;
  display:table-cell;
  vertical-align:bottom;
  position: relative;
  bottom: -6px;
}

.noticia-interna-legenda a {
  color: #8cc63e;
  white-space:nowrap;
}

.noticia-interna-img {
  height:325px;
  background-position:center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.noticia-interna-txt {
  height:325px;
  display:table;
}

.noticia-interna-txt-full {
  margin-top: 50px;
  font-size: 17px;
}

.noticia-interna-txt-full-intertitulo {
  margin-top:30px;
}


@media (max-width: $screen-md-max) {
  .mais-noticias-list p  {font-size:15px;}
}

@media (max-width: $screen-sm-max) {
  .noticia-interna-titulo {font-size:40px;padding-right: 0;}
  .noticia-interna-txt {height: auto;display: block;}
}

@media (max-width: $screen-xs-max) {
  .noticia-interna-titulo {font-size:26px;}
  .noticia-interna-legenda {font-size: 15px;}
  .noticia-interna-img {height: 200px;}
}




.noticia-interna-video {
  margin-top: 60px;
}

.video-container {
  position: relative;
  padding-bottom: 56%;
  padding-top: 0;
  height: 0;
  background: #2d3232;
  color: #FFF;
  text-align: center;
  margin-top: 80px;
}

.video-movie-titulo {
  margin-bottom:30px;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-shadow {
  width:100%;
  height:0;
  margin-top:-2px;
  padding-bottom: 74px;
  background-image: url(../images/sombra-video.png);
  background-position: center top;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  background-repeat:no-repeat;
}


.mais-noticias-thumb .mais-noticias-list span.mais-noticias-videothumb,
.mais-noticias-thumb .mais-noticias-list span.mais-noticias-infogthumb {
  display:block;
  margin-top: 12px;
  width:100%;
  position:relative;
  border-radius: 0;
  background:#2d3232;
  height: auto;
}

.mais-noticias-thumb .mais-noticias-list span.mais-noticias-infogthumb {
  background:transparent;
}

.mais-noticias-thumb .mais-noticias-list span.mais-noticias-videothumb span {
  width:74px;
  height:74px;
  position:absolute;
  top:50%;
  margin-top:-37px;
  left:50%;
  margin-left:-37px;
  border-radius:50%;
  font-size: 40px;
    overflow: hidden;
    padding-top: 26px;
}

.mais-noticias-videothumb img {
  width:100%;
  height:auto;
  opacity: 0.6;
}

.mais-noticias-infogthumb img {
  width:100%;
  height:auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.mais-noticias-videothumb-txt {
  display: block;
  background: #2d3232;
  color: #FFF;
  height: 65px;
  padding: 10px 20px 0;
  line-height: 22px;
  overflow:hidden;
}

.mais-noticias-infogthumb-txt {
  font-size:26px;
  display: block;
  background: transparent;
  color: #2d3232;
  height: 65px;
  padding: 15px 30px 0;
  overflow:hidden;
  font-weight:100;
  line-height:26px;
}

.mais-noticias-thumb-link {
  display:block;
  float:left;
  width:100%;
}

.mais-noticias-thumb-link:hover .mais-noticias-videothumb img {
  opacity:0.6;
}

.mais-noticias-thumb-link:hover .mais-noticias-infogthumb img {
  opacity:0.8;
}

@media (max-width: $screen-md-max) {
  .mais-noticias-videothumb-txt {font-size: 15px;letter-spacing: -0.3px;}
  .mais-noticias-infogthumb-txt {font-size:26px;}
}





.mais-noticias-thumb {
  margin-top:-90px;
}

.mais-noticias-thumb .mais-noticias-list-data {
  margin-bottom: 7px;
}

.mais-noticias-thumb .mais-noticias-list-data p {
  letter-spacing: 0;
  margin-top: 6px;
}

.mais-noticias-thumb .mais-noticias-list-data p span {
  position: absolute;
  width: 800px;
  height: 0;
  border-bottom: 1px solid #8cc63e;
  top: 7px;
  right: -812px;
}

.mais-noticias-thumb .mais-noticias-list span {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 15px;
}

.mais-noticias-thumb .mais-noticias-list h4 {
  font-size: 26px;
  margin-top: 5px;
  padding-right: 15px;
}


@media (max-width: $screen-xs-max) {
  .mais-noticias-thumb {margin-top:0;}
  .mais-noticias-thumb .mais-noticias-list {margin-top: -30px;margin-bottom: 60px;}
  .mais-noticias-thumb .mais-noticias-list h4 {padding-right: 0;}
}

/*=====  End of Noticias Aberta  ======*/






/*======================================
=            Artigos Aberto            =
======================================*/
.artigo-interna-titulo {
  font-weight:900;
  font-size:46px;
  margin-top: 5px;
  margin-top: 20px;
}


/*=====  End of Artigos Aberto  ======*/




/*====================================
=            Video Aberto            =
====================================*/
.form-ajuste-buscavideo {
  float:left;
  width:90%;
}

.mais-noticias-videothumb-txt.video-bgclean {
  background: rgba(45, 50, 50, 0.05);
  color:#2d3232;
}

.mais-noticias-videothumb.video-imgclean img {
  opacity:0.8;
}

.videothumb-mini {
  display: none;
  margin-top:20px;
}

.videothumb-mini-img {
  padding-right:0;
  position:relative;
  // background:#2d3232;
}

.videothumb-mini-img img {
  width:100%;
  // opacity: 0.8;
}

.videothumb-mini-img span {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  left: 50%;
  margin-left: -6px;
  border-radius: 50%;
  font-size: 16px;
  overflow: hidden;
  background: #8CC63E;
  color: #FFF;
  text-align:center;
  padding-top: 7px;
}

.videotube-mini-txt {
  font-size:15px;
  color:#2d3232;
  line-height:17.5px;
}

.videothumb-mini-bottomline {
  display:block;
  height:35px;
  border-bottom:1px solid #8CC63E;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom:40px;
  clear: both;
}

.video-apaga-luz {
  width: 31px;
  height: 60px;
  margin-top: 12px;
  float: right;
  border: none;
  background: url(http://clientes.nutsdigital.com.br/kantariboperepucom_conteudo/_imagens/isights/video-apaga-luz.png) top center;
}

.video-apaga-luz:focus {
  background: url(http://clientes.nutsdigital.com.br/kantariboperepucom_conteudo/_imagens/isights/video-apaga-luz.png) bottom center;
}


@media (max-width: $screen-sm-max) {
  .form-ajuste-buscavideo {width:100%;}
  .video-titulo-grande {text-align: center;}
  .video-apaga-luz {position: absolute;top: -172px;right: -110px;}
}

@media (max-width: $screen-xs-max) {
  .videothumb-mini {display:block;}
  .video-apaga-luz {position: absolute;top: -185px;right:0;}
}

/*=====  End of Video Aberto  ======*/






/*====================================
=            Infográficos            =
====================================*/
.infografico-thumb-full {
  width:100%;
  float:left;
  height:auto;
  position:relative;
  margin-top: 65px;
  margin-bottom: 45px;
}

.imfografico-img-p {
  width:35%;
  float:left;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.imfografico-txt-p {
  display:block;
  width:65%;
  position: absolute;
  right: 0;
  bottom: 60px;
  padding-right: 8%;
}

.imfografico-txt-p h3 a {
  font-size:36px;
  margin-left:35px;
  margin-bottom: 7px;
  color: #333;
  text-decoration: none;
}

.imfografico-txt-p p {
  font-size:11px;
  font-weight:700;
  text-transform:uppercase;
  color: rgba(45, 50, 50, 0.5);
  margin-left:36px;
  letter-spacing: 5px;
}

.infografico-borda-01 {
  display:block;
  position:absolute;
  left:35%;
  bottom:10px;
  width:50%;
  height:48px;
  border-top: 1px solid #8CC63E;
  border-right: 1px solid #8CC63E;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px;
}

.infografico-borda-02 {
  display:block;
  position:absolute;
  left:85%;
  bottom:0;
  width:15%;
  height:10px;
  border-bottom: 1px solid #8CC63E;
  border-left: 1px solid #8CC63E;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-left-radius: 5px;
  margin-left:-1px;
}

.infografico-link {
  display:block;
  width: 35px;
  height: 35px;
  line-height: 30px;
  font-size: 30px;
  border: none;
  font-weight: 100;
  color: #FFF;
  background:#8CC63E;
  -webkit-border-radius: 5px;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius: 5px;
  -moz-border-radius-bottomright: 0;
  border-radius: 5px;
  border-bottom-right-radius: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: center;
  padding-left: 2px;
  padding-top:1px;
}

.infografico-link:hover {
  color:#FFF;
  text-decoration:none;
}


@media (max-width: $screen-sm-max) {
  .imfografico-txt-p {padding-right: 15%;}
  .imfografico-txt-p h3 {font-size:32px;margin-bottom: 3px;}
}

@media (max-width: $screen-xs-max) {
  .infografico-thumb-full {padding-bottom: 20px;margin-top: 40px;margin-bottom: 25px;}
  .imfografico-txt-p {width:60%;padding-right: 0;bottom: 10px;}
  .imfografico-img-p {width:40%;}
  .imfografico-txt-p h3 {font-size:15px;font-weight: 300;margin-bottom: 3px;margin-left: 10px;letter-spacing: 0;}
  .imfografico-txt-p p {font-size: 9px;margin-left: 10px;letter-spacing: 0;}
  .infografico-link {width: 20px;height: 20px;line-height: 15px;font-size: 17px;font-weight: 300;padding-left:1px;}
  .infografico-borda-01 {display:none;}
  .infografico-borda-02 {left: 20%;width:80%;height:20px;}
}

/*=====  End of Infográficos  ======*/





/*===========================================
=            Infográficos Aberto            =
===========================================*/
.infografico-inter-data {
  font-size:11px;
  font-weight:700;
  text-transform:uppercase;
  color: rgba(45, 50, 50, 0.5);
  letter-spacing: 5px;
}

.infografico-container {
  margin-top: 35px;
}

.infografico-veja-tambem .infografico-thumb-full {
  padding-bottom: 20px;
  margin-top: 0;
  margin-bottom: 60px;
}

.infografico-veja-tambem .infografico-thumb-full a {
  display:block;
}

.infografico-veja-tambem .infografico-thumb-full a:hover img {
  // opacity:0.7;
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

.infografico-veja-tambem .imfografico-txt-p {
  width:60%;
  padding-right: 0;
  bottom: 10px;
}

.infografico-veja-tambem .imfografico-img-p {
  width:40%;
}

.infografico-veja-tambem .imfografico-txt-p h3 {
  font-size:15px;
  font-weight: 300;
  margin-bottom: 3px;
  margin-left: 10px;
  letter-spacing: 0;
  color:#2d3232;
}

.infografico-veja-tambem .imfografico-txt-p p {
  font-size: 9px;
  margin-left: 10px;
  letter-spacing: 0;
  color:#2d3232;
}

.infografico-veja-tambem .infografico-link {
  width: 20px;
  height: 20px;
  line-height: 15px;
  font-size: 17px;
  font-weight: 300;
  padding-left:1px;
}

.infografico-veja-tambem .infografico-borda-01 {
  display:none;
}

.infografico-veja-tambem .infografico-borda-02 {
  left: 20%;
  width:80%;
  height:20px;
}

@media (max-width: $screen-xs-max) {
  .infografico-inter-data {letter-spacing: 2px;}
  // .infografico-container {margin-left: -45px;margin-right: -45px;}
}

/*=====  End of Infográficos Aberto  ======*/





/*====================================
=            Fale Conosco            =
====================================*/
.contato-sub {
  font-size:17px;
}

.clique-aqui {
  color:#8CC63E;
  text-decoration: underline;
  font-weight:700;
  display: inline-block;
  white-space: nowrap;
}

.clique-aqui:hover {
  color:#8CC63E;
}

.btn-fale-conosco {
  width: 130px !important;
  background:#f4f4f4 !important;
  height: 50px;
  float: right;
  background: #8cc63e;
  border: none;
  width: 147px;
  font-size: 17px;
  font-weight: 300;
  border:none;
}

.btn-fale-conosco:hover {
  background:#8CC63E !important;
}

.btn-fale-conosco span {
  font-size: 18px !important;
  color:#2d3232 !important;
  position:relative !important;
  font-size: 18px !important;
  top: 3px !important;
  right: -5px !important;
}

.btn-fale-conosco span.microline {
  display:inline-block;
  position:relative !important;
  top: -5px !important;
  right: -14px !important;
  margin-left: -5px !important;
  width:20px !important;
  height:0!important;
  border-bottom: 1px solid rgba(45, 50, 50, 0.4);
}

.form-group textarea {
  width:100%;
  height: 155px;
  color: #2d3232;
  border: 1px solid #2d3232;
  font-size: 17px;
  font-weight: 300;
  text-indent: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  resize: none;
  padding: 11px 23px;
    text-indent: 0;
}

.form-group textarea::placeholder {
  color:#2d3232;
}

.form-group textarea.textarea-fale-conosco::placeholder {
  padding-top:48px;
}

.form-group {
    margin-bottom: 20px;
}

.contato-placeholder {
  // background: #F4F4F4;
  color: #2d3232;
  padding: 0 15px;
  height: 48px;
  line-height: 48px;
  float: left;
  position: absolute;
  z-index: 69;
  top: 1px;
  left: 1px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.textearea-placeholder {
  // background: #F4F4F4;
  color:#2d3232;
  padding:0 15px;
  height: 153px;
  line-height: 153px;
  float:left;
  position:absolute;
  z-index: 69;
  top: 1px;
  left: 1px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}


@media (max-width: $screen-xs-max) {
  .contato-placeholder, .textearea-placeholder {
    position: relative;
    height: 20px;
    line-height: 20px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 6px;
    font-size:15px;
    background:transparent;
    left: 0;
    top: 0;
  }
  .form-group textarea {padding:11px 15px;font-size:15px;}
  .estudos-dados-form input, .estudos-dados-form select {font-size:15px;}
  .estudos-dados-form input {text-indent:2px;}
}

/*=====  End of Fale Conosco  ======*/




/*======================================================
=            Solicite Orçamento de Pesquisa            =
======================================================*/
.orc-opcoes h4 {
  color:#8CC63E;
  margin-bottom: 18px;
  margin-top: 25px;
}

.orc-opcoes p {
  border-bottom: 1px solid #2d3232;
  margin-bottom: 12px;
  padding-bottom: 10px;
}

.orc-opcoes-center {
  text-align:center;
  margin-top:35px;
  margin-bottom:68px;
}

@media (max-width: $screen-sm-max) {
  .orc-opcoes h4 {font-size:17px;}
}

/*=====  End of Solicite Orçamento de Pesquisa  ======*/









/*======================================
=            Ajustes Form 7            =
======================================*/
.margintop40 {
  margin-top:40px;
}

.margintop60 {
  margin-top:60px;
}

.formulario-contato input::placeholder,
.formulario-contato textarea::placeholder {
  color:transparent !important;
  font-size:0 !important;
}

.formulario-contato span {
  position:relative;
  display:block;
}

.formulario-contato span.asterisco {
  position: absolute;
  top: 2px;
  left: 22px;
  z-index: 69;
}

.formulario-contato span.asterisco::before {
  content: "*";
  opacity: 0.6;
}

.formulario-contato select, .formulario-contato input {
  height: 50px;
  color: #2d3232;
  border: 1px solid #2d3232;
  font-size: 17px;
  font-weight: 300;
  text-indent: 7px;
  background: transparent;
    width: 100%;
}

div.wpcf7-validation-errors {
  border: 1px solid #f7e700;
  font-size: 17px;
  font-weight: 300;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 35px 0;
}

span.wpcf7-not-valid-tip {
  font-weight: 300;
}

.btn-dados-download {
  height: 50px;
  background: #8cc63e;
  border: none;
  width: 147px;
  font-size: 17px;
  font-weight: 300;    height: 50px;
  background: #8cc63e;
  border: none;
  width: 147px;
  font-size: 17px;
  font-weight: 300;
}

.btn-dados-download span {
  margin-left: 5px;
  color: #FFF;
  font-size: 21px;
}

.btn-dados-download:hover span {
  color:#8cc63e;
}

.wpcf7-list-item-label {
  font-size:15px;
  font-weight:300;
  margin-left: 10px;
}

.checkbox-ajuste {
  cursor: pointer;
}

.checkbox-ajuste input[type=checkbox], .checkbox-ajuste input[type=radio] {
  position:relative !important;
  font-size:17px;
  float: left;
  margin-left: 0px;
  margin-right: -2px;
  top: -1px;
  cursor: pointer;
}

span.wpcf7-form-control-wrap.checkbox {
  margin:0 !important;
}

.orc-opcoes p.fineajust {
    border-bottom: 1px solid #2d3232;
    margin-bottom: 10px;
    padding-bottom: 4px;
}

.orc-opcoes p.fineajust .checkbox-ajuste input[type=checkbox] {
  top:1px;
}

/*=====  End of Ajustes Form 7  ======*/


img {
  margin: 0 auto !important;
}



/*===========================================
=            Mapa - Onde Estamos            =
===========================================*/

.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, arial;
  font-size: 15px;
  padding: 5px 12px;
  font-weight: 300;
}

.jqvmap-zoomin, .jqvmap-zoomout {
  position: absolute;
  left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #000000;
  padding: 3px;
  color: white;
  width: 10px;
  height: 10px;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
}

.jqvmap-zoomin {
  top: 10px;
}

.jqvmap-zoomout {
  top: 30px;
}

.jqvmap-region {
  cursor: pointer;
}

.jqvmap-ajax_response {
  width: 100%;
  height: 500px;
}


#vmap_repucom, #vmap_global {
  margin: 0 auto;
  width: 900px;
  height: 600px;
}

#vmap_global {
  display: none;
}

#vmap_repucom div, #vmap_global div {
  width:100%;
  height:100%;
  position:absolute;
  z-index:666;
}

.jqvmap-zoomin, .jqvmap-zoomout {
  display: none;
}

.mapa-container {
  display: block;
  clear: both;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  background: #5f6716; /* Old browsers */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiM1ZjY3MTYiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMWYzYjA4IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-radial-gradient(center, ellipse cover,  #5f6716 0%, #1f3b08 100%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,#5f6716), color-stop(100%,#1f3b08)); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover,  #5f6716 0%,#1f3b08 100%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover,  #5f6716 0%,#1f3b08 100%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover,  #5f6716 0%,#1f3b08 100%); /* IE10+ */
  background: radial-gradient(ellipse at center,  #5f6716 0%,#1f3b08 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5f6716', endColorstr='#1f3b08',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
}

.maptitle {
  text-align: center;
  margin-bottom: -12px;
  width: 100%;
  float:left;
}

.maptitle h1 {
  color: #FFF;
  margin-bottom: 15px;
}

.maptitle button {
    background: rgba(255, 255, 255, 0.15);
    border: none;
    width: 200px;
    height: 40px;
    font-size: 17px;
    font-weight: 300;
    color: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.maptitle button.selecionado {
  background: #fff;
  color: #8cc63e;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(255,255,255,1) !important;
  -moz-box-shadow: 0px 0px 30px 0px rgba(255,255,255,1) !important;
  box-shadow: 0px 0px 30px 0px rgba(255,255,255,1) !important;
}

.maptitle button:focus {
  outline: none;
}

.maplinks {
  color:#FFFFFF;
  width: 50%;
  min-height:70px;
  margin: 20px auto 0;
}

.maplinks button {
  width: auto;
    height: auto;
    background:transparent;
    color: #FFFFFF;
    padding: 0;
    margin: 0;
}

.maplinks button::after {
  content: "•";
  margin-left:3px;
  display:inline-block;
  font-weight:300 !important;
  position: relative;
  top: 2px;
}

.maplinks button:last-child::after {
  content: "";
  margin:0;
}

.maplinks button:focus, .maplinks button:active {
  font-weight:700;
  letter-spacing: -0.2px;
}

#links_repucomglobal {
  display: none;
}


@media (max-width: $screen-sm-max) {
  #vmap_repucom, #vmap_global {width: 700px;height: 467px;}
  .maplinks {width:90%;}
}

@media (max-width: $screen-xs-max) {
  #vmap_repucom, #vmap_global {width: 300px;height: 280px;padding-top: 40px;}
  .maplinks {width:90%; margin-top: 50px;}
  .maplinks button {width: 90%; padding: 0; margin: 0; text-align: left; border-bottom: 1px solid; border-radius: 0; padding-bottom: 2px; margin-bottom: 10px; position: relative;}
  .maplinks button::after, .maplinks button:last-child::after {content: ">"; display:block; font-weight:300 !important; position: absolute; top: 2px; right: 0;}
}

/*=====  End of Mapa - Onde Estamos  ======*/





/*==========================================
=            Resultado da Busca            =
==========================================*/

body.error404 h1,
body.search-results h1,
body.search-no-results h1,
body.author h1 {
  font-size: 42px;
}

body.error404 h2,
body.search-results h2,
body.search-no-results h2,
body.author h2 {
  font-size: 25px;
  letter-spacing: 0;
}

body.search-results h1 a,
body.search-results h1 a,
body.search-no-results h1 a,
body.author h1 a {
  color: #8CC63E;
  font-weight: 300;
}

body.search-results .entry-summary,
body.search-no-results .entry-summary,
body.author .entry-summary {
  font-size: 17px;
  font-weight: 300;
  margin-bottom: 35px;
  padding-bottom: 40px;
  border-bottom: 1px solid #C5C5C5;
}

body.search-results article,
body.search-no-results article,
body.author article {
  padding: 0 10%;
}

code {
  padding: 2px 5px;
  font-size: 90%;
  color: #8CC63E;
  border-radius: 4px;
  background: transparent;
}

code::before {
  content: "'";
}

code::after {
  content: "'";
}

body.search-results .entry-summary a,
body.search-no-results .entry-summary a,
body.author .entry-summary a {
  white-space: nowrap;
}

body.error404 .alert-warning,
body.search-no-results .alert-warning {
  background-color: transparent;
  border-color: #8CC63E;
  color: #2d3232;
  font-size: 17px;
  font-weight: 300;
  padding: 50px 0;
  text-align: center;
  width: 80%;
  margin: 0px auto;
}

body.error404 .alert-warning::before,
body.search-no-results .alert-warning::before {
  content: "!";
  font-weight: 900;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 50%;
  margin-right: 10px;
  color: #FFFFFF;
  background: #2d3232;
}

/*=====  End of Resultado da Busca  ======*/

.gallery {
  display: block;
}
.gallery:after, .gallery:before {
  content: " ";
  display: table;
}
.gallery:after {
  clear: both;
}
.gallery .gallery-item  {
  float: left;
}
.gallery img {
  width: 100%;
  height: auto;
}
.gallery-columns-1 .gallery-item {
    width: 100%;
    float: none;
}
.gallery-columns-2 .gallery-item {
    width: 50%
}
.gallery-columns-3 .gallery-item {
    width: 33.3333%
}
.gallery-columns-4 .gallery-item {
    width: 25%
}
.gallery-columns-5 .gallery-item {
    width: 20%
}
.gallery-columns-6 .gallery-item {
    width: 16.6667%
}
.gallery-columns-7 .gallery-item {
    width: 14.2857%
}
.gallery-columns-8 .gallery-item {
    width: 12.5%
}
.pswp__button--arrow--left, .pswp__button--arrow--right {
	position: absolute !important;
}
