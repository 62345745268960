.navbar-nav .open>a, .navbar-nav .open>a:focus, .navbar-nav .open>a:hover {
    background-color: transparent !important;
    border-color: transparent !important;
}
a.noticia-interna-retranca {
	color: #333 !important;
	text-decoration: none !important;
}
input.search-busca-rapida {
	height: 46px !important;
    font-size: 17px !important;
    border: none !important;
}
.btn-busca-rapida {
	background: #2d3232 !important;
	color: #abacac !important;
	font-size: 17px !important;
	font-weight: 300 !important;
}
.btn-busca-rapida:focus {
	border: none !important;
}
.btn-busca-rapida:active, .btn-busca-rapida:focus, .btn-busca-rapida:hover, .btn-busca-rapida:visited {
	color: #adff00 !important;
	font-weight: 700 !important;
	outline: 0 !important;
}
.contem-busca-rapida {
	padding-top: 10px;
	padding-bottom: 20px;
}
#isotope .item[class^="col-"], #isotope-noticias .item[class^="col-"] {
	padding-right: 1px !important;
	padding-left: 1px !important;
}
@media (max-width: $screen-xs-max) {
	.links-destaque {height: 230px !important;}
}

.top-celebridades a .icon-celebridades {
	top: 15px;
	left: 32px;
}

#noticias-destaque .carousel-inner {
	width: inherit !important;
}
.icone-busca-estudos {
	margin-top: -33px !important;
}
#noticias-destaque {
	margin-top: 20px;
	padding-right: 15px;
	padding-left: 15px;
}