//- Revolution

.tp-bullet {
	border-radius: 50% !important;
}
.custom .tp-bullet:hover, .custom .tp-bullet.selected {
    background: #8cc63e !important;
}

/* Menu Idiomas */


// NAVBAR ANIMAÇÃO
  // .navbar-toggle {
  //   margin-top: 32px;
  //   background: none !important;
  //   border: none;
  // }

  // .navbar-default .navbar-toggle .icon-bar {
  //   background-color: #c7a9a6;
  // }
  .navbar-toggle .btn-menu-mobile:nth-of-type(2) {
      top: 1px;
  }
  .navbar-toggle .btn-menu-mobile:nth-of-type(3) {
      top: 2px;
  }
  .navbar-toggle .btn-menu-mobile {
      position: relative;
      transition: all 500ms ease-in-out;
  }
  .navbar-toggle.active .btn-menu-mobile:nth-of-type(1) {
      top: 4.5px;
      transform: rotate(45deg);
  }
  // .navbar-toggle.active .btn-menu-mobile:nth-of-type(2) {
  //     background-color: transparent;
  // }
  .navbar-toggle.active .btn-menu-mobile:nth-of-type(2) {
      top: -4.5px;
      transform: rotate(-45deg);
  }

.idiomas {
  position: absolute;
  top: 0px;
  list-style: none;
  padding: 0;
  margin: 0;
  right: 15px;
}
.idiomas li {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.idiomas li {
  padding: 0 0 0 4px;
}
.idiomas li img {
  width: 15px;
}
.idiomas li>a {
  padding: 5px 0px 0px;
  display: none;
  width: 20px;
}
.idiomas li.active>a, .idiomas li.current-lang>a {
  // background: rgb(140, 198, 62);
}
.lang-item-br.active>a, .lang-item-br.current-lang>a {
  // background: rgb(34, 158, 69);
  background: #27a138;
}
.lang-item-us.active>a, .lang-item-us.current-lang>a  {
  // background: rgb(34, 158, 69);
  background: rgb(189, 61, 68);
}
.lang-item-es.active>a, .lang-item-es.current-lang>a  {
  // background: rgb(34, 158, 69);
  background: #bf0600;
}

/*  Menu  */

.arrow-up {
	width: 0;
	height: 0;
	border-left: 0px solid transparent;
	border-right: 40px solid transparent;
	border-bottom: 28px solid #8cc63e;
	position: absolute;
    top: 48px;
    left: 50%;
}
.sub-menu-arrasta.jumbo {
	padding-top: 10px;
}
.sub-menu>li>a>span {
	font-size: 19px;
	position: relative;
	top: 2px;
	right: 3px;
}
.sub-menu>li.dropdown>a {
    font-weight: 900;
    font-size: 21px;
}
.sub-menu>li.dropdown>a>span {
	font-size: 22px;
}
// .menu-principal>.menu-item>a:before {
// 	content: "|";
// }
#menu-modificado>.sub-menu-arrasta {
	display: block;
	margin: 0 0 20px;
  // background: red;
}
#menu-modificado h1 {
  font-size: 36px;
  margin-top: 20px;
}
.menu-item[class^="icon-"] {
	// font-size:
}
// .menu-item>.sub-menu>.menu-item {
// 	background: blue;
// }

// .menu-item>.sub-menu {
// 	display: block;
// 	background: green;
// }

// .menu-item>.sub-menu>.menu-item {
// 	background: red;
// }

.coluna-menu {
	border-left: 1px dashed #2d3232;
	padding-left: 15px;
	margin: 0 0 20px 10px !important;
	min-height: 220px;
}

.coluna-menu li {
	border-bottom: 1px solid #2d3232;
}
.coluna-menu li:last-child {
	border-bottom: none;
}

@media (min-width: $screen-sm-min) {
  .dropdown-menu {
    display: none !important;
  }
}
@media (min-width: $screen-md-min) {
  .modal-dialog {
    width: 930px;
  }
}

.navbar-nav>li::after {
    content: '|';
    color: #a7a9ac !important;
    position: absolute;
    top: 12px;
    font-size: 17px;
}
.navbar-nav>li:last-child::after {
	content: '';
}
.navbar-nav>li:first-child::after {
	content: '';
}
#menu-modificado a {
	color: #2d3232 !important;
}
#menu-modificado {
	display: block;
	background: #8cc63e;
	color: #2d3232;
}
// .menu-principal .sub-menu {
// 	display: none !important;
// }
#menu-modificado .sub-menu {
  display: block;
}
#menu-modificado li {
	min-height: 38px;
}

@media (max-width: $screen-xs-max) {
  #menu-modificado {
    display: none !important;
  }
  ul.menu-principal .ativado {
    display: block;
  }
  .navbar-nav>li::after {
    content: " " !important;
    position: absolute;
  }
  ul.menu-principal li {
    border-bottom: 1px solid #000;
  }
  ul.menu-principal li:last-child {
    border-bottom: none;
  }
  ul.menu-principal a span {
    float: right !important;
    font-size: 15px !important;
  }
  ul.menu-principal a {
    padding: 15px 0px 10px !important;
    color: #000 !important;
    font-weight: 100 !important;
    font-size: 17px !important;
  }
  ul.menu-principal .dropdown.open>a {
    font-size: 30px !important;
  }
  ul.menu-principal .dropdown.open>a span {
    font-size: 30px !important;
    margin-right: 18px;
  }
  ul.menu-principal .sub-menu {
    margin: 0;
  }
  ul.menu-principal .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    background: inherit;
  }
  ul.menu-principal a:focus, ul.menu-principal a:hover {
    color: #000 !important;
    font-weight: 100;
  }
  .menu-principal.navbar-nav {
    margin-top: 23px;
    padding: 20px 0;
  }
  .idiomas {
    top: 15px;
  }
  .idiomas li>a {
    padding: 20px 0px 0px;
  }
  .idiomas li img {
    width: 25px;
  }
  .contem-busca-rapida {
    margin: 0 15px;
  }
  .dropdown-menu {
    position: inherit !important;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    width: 100%;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: inherit !important;
    text-align: left;
    background-color: inherit !important;
    border: none !important;
    border-radius: inherit !important;
    -webkit-box-shadow: inherit !important;
    box-shadow: inherit !important;
    -webkit-background-clip: inherit !important;
    background-clip: inherit !important;
  }
  ul.menu-principal>li>a::after {
    content: ">";
    float: right;
    font-size: 20px;
  }
  ul.menu-principal>li.open>a::after {
    content: "";
    float: right;
    font-size: 20px;
  }
  ul.menu-principal>li>ul>li>a::before {
    content: "> ";
    font-size: 20px;
  }
  ul.menu-principal>li>ul>li.open>a::before {
    content: "" !important;
    display: none;
  }
  // ul.menu-principal>ul>li.menu-item.open .dropdown-menu {
  //   display: block !important;
  // }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	.navbar-right {
	    float: left !important;
	    left: -8px;
	    position: relative;
	}
  .idiomas {
    top: 15px;
  }
  .idiomas li>a {
    padding: 20px 0px 0px;
  }
  .idiomas li img {
    width: 25px;
  }
	.arrow-up {
		top: 49px;
		border-right: 60px solid transparent;
	}
}
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

}
@media (min-width: $screen-lg-min) {

}

// .sub-menu-arrasta a {
// 	color: #2d3232;
// }
// .sub-menu-arrasta.menu-quem-somos .sub-menu {
// 	display: block;
// }
// .sub-menu-arrasta.menu-quem-somos {
// 	display: block;
// 	z-index: 99999;
// 	position: relative;
// }
// .sub-menu-arrasta {
// 	position: absolute;
// 	z-index: 99999;
// 	width: 100%;
// }
